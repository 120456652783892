import Vue from 'vue'
import VueRouter from 'vue-router'
import Clustering from '../views/Clustering'
import GeneExpression from '../views/GeneExpression'
import DEG from '../views/DEG'
import BiologicalPathway from '../views/BiologicalPathway'
import AASBDEG from '../views/AASBDEG'
import AndrEffect from '../views/AndrEffect'
import About from '../views/About'
import Download from '../views/Download'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'clustering',
        component: Clustering
    }, {
        path: '/gene_exp',
        name: 'GeneExpression',
        component: GeneExpression
    }, {
        path: '/deg',
        name: 'DEG',
        component: DEG
    }, {
        path: '/bio_pathway',
        name: 'BiologicalPathway',
        component: BiologicalPathway
    }, {
        path: '/aasb_deg',
        name: 'AASBDEG',
        component: AASBDEG
    }, {
        path: '/andr_effect',
        name: 'AndrEffect',
        component: AndrEffect
    }, {
        path: '/about',
        name: 'About',
        component: About
    }, {
        path: '/download',
        name: 'Download',
        component: Download
    },
]
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router