var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"block-container"},[_c('el-card',{staticClass:"block-card"},[_c('div',{staticClass:"box-card-deg"},[_c('div',{staticClass:"box-card-item"},[_c('span',{staticClass:"box-card-item-icon"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-code-compare"}})],1),_c('span',[_vm._v(" DEG (Differentially expressed gene)")])])]),_c('div',{staticClass:"select-container"},[_c('div',{staticClass:"select-components"},[_c('div',{staticClass:"select-title"},[_vm._v("Tissue")]),_c('div',{staticClass:"select-content"},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.changeTissue(_vm.tissueValue)}},model:{value:(_vm.tissueValue),callback:function ($$v) {_vm.tissueValue=$$v},expression:"tissueValue"}},_vm._l((_vm.tissueOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('div',{staticClass:"select-components"},[_c('div',{staticClass:"select-title"},[_vm._v("Comparison")]),_c('div',{staticClass:"select-content"},[_c('el-select',{staticStyle:{"width":"100%"},on:{"change":function($event){return _vm.filterTable(
                _vm.tissueValue,
                _vm.comparisonValue,
                _vm.cellTypeValue,
                _vm.changeValue
              )}},model:{value:(_vm.comparisonValue),callback:function ($$v) {_vm.comparisonValue=$$v},expression:"comparisonValue"}},_vm._l((_vm.comparisonOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('div',{staticClass:"select-components"},[_c('div',{staticClass:"select-title"},[_vm._v("Cell type")]),_c('div',{staticClass:"select-content"},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.filterTable(
                _vm.tissueValue,
                _vm.comparisonValue,
                _vm.cellTypeValue,
                _vm.changeValue
              )}},model:{value:(_vm.cellTypeValue),callback:function ($$v) {_vm.cellTypeValue=$$v},expression:"cellTypeValue"}},_vm._l((_vm.cellTypeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('div',{staticClass:"select-components"},[_c('div',{staticClass:"select-title"},[_vm._v("Change")]),_c('div',{staticClass:"select-content"},[_c('el-select',{staticStyle:{"width":"100%"},on:{"change":function($event){return _vm.filterTable(
                _vm.tissueValue,
                _vm.comparisonValue,
                _vm.cellTypeValue,
                _vm.changeValue
              )}},model:{value:(_vm.changeValue),callback:function ($$v) {_vm.changeValue=$$v},expression:"changeValue"}},_vm._l((_vm.changeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)])]),_c('div',{staticClass:"box-card-table"},[_c('Table',{ref:"table",attrs:{"border":"","stripe":"","loading":_vm.tableLoading,"columns":_vm.tableTitleColumns,"data":_vm.tableData,"size":"default"}}),_c('div',{staticClass:"table-page"},[_c('Page',{attrs:{"total":_vm.total,"current":_vm.pageNum,"page-size":_vm.pageSize,"show-total":"","show-elevator":"","show-sizer":"","page-size-opts":[10, 20, 50, 100, 200, 500]},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.handlePageSize}})],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }