<template>
  <div id="navigate">
    <div class="menu">
      <div class="logo">
        <a alt="">
          <el-image class="logo-img" :src="src">
            <div slot="placeholder" class="image-slot">
              Loading<span class="dot">...</span>
            </div>
          </el-image>
        </a>
      </div>
      <!-- tabbar -->
      <div class="tabbar">
        <el-menu
          :default-active="activeIndex"
          :router="true"
          class="menu-content"
          mode="horizontal"
        >
          <!-- @select="handleSelect" -->
          <el-menu-item index="/">
            <font-awesome-icon icon="fa-solid fa-circle-nodes" />
            <span slot="title"> Clustering</span>
          </el-menu-item>
          <el-menu-item index="/gene_exp">
            <font-awesome-icon icon="fa-solid fa-dna" />
            <span slot="title"> Gene expression</span>
          </el-menu-item>
          <el-menu-item index="/deg">
            <font-awesome-icon icon="fa-solid fa-code-compare" />
            <span slot="title"> DEG</span>
          </el-menu-item>
          <el-menu-item index="/bio_pathway">
            <font-awesome-icon icon="fa-solid fa-wave-square" />
            <span slot="title"> Biological pathway</span>
          </el-menu-item>
          <el-menu-item index="/aasb_deg">
            <font-awesome-icon
              icon="fa-solid fa-down-left-and-up-right-to-center"
            />
            <span slot="title"> AASB-DEG</span>
          </el-menu-item>
          <el-menu-item index="/andr_effect">
            <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left" />
            <span slot="title"> Androgen's effect classification</span>
          </el-menu-item>
          <el-menu-item index="/about">
            <font-awesome-icon icon="fa-solid fa-people-roof" />
            <span slot="title"> About</span>
          </el-menu-item>
          <el-menu-item index="/download">
            <font-awesome-icon icon="fa-solid fa-download" />
            <span slot="title"> Download</span>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- tabbar-mobile -->
      <div class="tabbar-mobile">
        <el-menu :default-active="activeIndex" :router="true" mode="horizontal">
          <el-submenu index="">
            <template slot="title">Menu</template>
            <el-menu-item index="/">
              <font-awesome-icon icon="fa-solid fa-circle-nodes" />
              <span slot="title"> Clustering</span>
            </el-menu-item>
            <el-menu-item index="/gene_exp">
              <font-awesome-icon icon="fa-solid fa-dna" />
              <span slot="title"> Gene expression</span>
            </el-menu-item>
            <el-menu-item index="/deg">
              <font-awesome-icon icon="fa-solid fa-code-compare" />
              <span slot="title"> DEG</span>
            </el-menu-item>
            <el-menu-item index="/bio_pathway">
              <font-awesome-icon icon="fa-solid fa-wave-square" />
              <span slot="title"> Biological pathway</span>
            </el-menu-item>
            <el-menu-item index="/aasb_deg">
              <font-awesome-icon
                icon="fa-solid fa-down-left-and-up-right-to-center"
              />
              <span slot="title"> AASB-DEG</span>
            </el-menu-item>
            <el-menu-item index="/andr_effect">
              <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left" />
              <span slot="title"> Androgen's effect classification</span>
            </el-menu-item>
            <el-menu-item index="/about">
              <font-awesome-icon icon="fa-solid fa-people-roof" />
              <span slot="title"> About</span>
            </el-menu-item>
            <el-menu-item index="/download">
              <font-awesome-icon icon="fa-solid fa-download" />
              <span slot="title"> Download</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import casaLogo from "../assets/logo.svg";
export default {
  name: "NavigateBar",
  data() {
    return {
      src: casaLogo,
      activeIndex: "/",
    };
  },
  methods: {
    // handleSelect(key, keyPath) {
    // console.log(key, keyPath);
    // },
  },
};
</script>

<style scoped>
#navigate {
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
/* menu container */
.menu {
  /* width: 1320px; */
  width: 1460px;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid green; */
}
/* logo */
.logo {
  margin-top: 15px;
  display: inline-block;
}
.logo-img {
  height: 32px;
}
/* tabbar */
.tabbar {
  width: 1300px;
  display: inline-block;
  /* border: 1px solid blue; */
}
.menu-content {
  width: 1350px;
  border: none;
  /* border: 1px solid red; */
}
.el-menu.el-menu--horizontal {
  border: none;
  display: inline-block;
}
.el-menu--horizontal > .el-menu-item {
  font-size: 17px;
  border: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border: none;
}

/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .menu {
    width: 90%;
  }
}
@media screen and (max-width: 1320px) {
  .tabbar {
    display: none;
  }
}
@media screen and (min-width: 1320px) {
  .tabbar-mobile {
    display: none;
  }
}
</style>