import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design'
import locale from 'view-design/dist/locale/en-US';
import 'view-design/dist/styles/iview.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
// import './utils/flexible'
import axios from 'axios'
import VueAxios from 'vue-axios'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faCircleNodes, faDna, faCodeCompare, faWaveSquare, faDownLeftAndUpRightToCenter, faArrowsSplitUpAndLeft, faPeopleRoof, faDownload, faBookmark, faCircleInfo, faClipboardQuestion, faAnglesUp, faUserTag, faIdCard, faEnvelope, faDrawPolygon } from '@fortawesome/free-solid-svg-icons'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* add icons to the library */
library.add(faCircleNodes, faDna, faCodeCompare, faWaveSquare, faDownLeftAndUpRightToCenter, faArrowsSplitUpAndLeft, faPeopleRoof, faDownload, faBookmark, faCircleInfo, faClipboardQuestion, faAnglesUp, faUserTag, faIdCard, faEnvelope, faDrawPolygon)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueAxios, axios)
// axios.defaults.baseURL = 'http://127.0.0.1:8000'
// axios.defaults.baseURL = 'http://10.20.162.171:8000'
axios.defaults.baseURL = 'api/'
Vue.prototype.$axios = axios
Vue.config.productionTip = false

Vue.use(ViewUI, { locale })
Vue.use(ElementUI)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
