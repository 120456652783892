<template>
  <div>
    <!-- Banner -->
    <div class="banner">
      <div class="banner-text">
        <span class="banner-text-title">CASA (Cell Atlas involving Sex and Androgens)</span>
        <div class="banner-text-desc">
          CASA is a single-cell transcriptomic atlas involving sex and androgens comprising a total of 2,337,431 cells
          from 17 tissues in the organism of
          <span style="font-style: italic">Mus musculus</span>, which provides the customizable visualization and analyses
          for sex differences and their modulation by androgens at single-cell resolution.
        </div>
      </div>
    </div>
    <div class="mobile-banner">
      <img class="mobile-banner-img" src="@/assets/m-clustering-banner.jpg" />
      <div class="mobile-banner-text">
        <div class="mobile-banner-text-desc">
          CASA is a single-cell transcriptomic atlas involving sex and androgens comprising a total of 2,337,431 cells
          from 17 tissues in the organism of
          <span style="font-style: italic">Mus musculus</span>, which provides the customizable visualization and analyses
          for sex differences and their modulation by androgens at single-cell resolution.
        </div>
      </div>
    </div>
    <!-- Block -->
    <div class="block-container">
      <el-card class="block-card">
        <!-- Clustering -->
        <div id="clustering" class="box-card-clustering">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-circle-nodes" />
            </span>
            <span> Clustering</span>
          </div>
          <div class="box-card-clustering-content">
            <el-row>
              <el-col :span="9">
                <div class="clustering-left">
                  <el-image class="mouse-img" :src="mouseImgUrl"></el-image>
                  <div id="the-number-of-cells" class="tissue-number">
                    {{ clusteringCellNumber }} cells
                  </div>
                </div>
              </el-col>
              <el-col :span="15">
                <div class="clustering-right">
                  <div class="tip">
                    <font-awesome-icon icon="fa-solid fa-circle-info" />
                    <span>
                      Select a tissue below to view cell type composition and
                      hover one to view the number of cells.</span>
                  </div>
                  <div class="tissue-btns">
                    <el-row :gutter="12">
                      <el-col :span="8" v-for="(tissue, index) in tissueCardsInfo" :key="index">
                        <el-card :shadow="activeTissueID == index ? 'always' : 'hover'" :body-style="activeTissueID == index
                            ? 'padding: 12px 20px; background-color: #223468; color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)'
                            : 'padding: 12px 20px;'
                          " @mouseenter.native="onCard(tissue.number)" @mouseleave.native="offCard()"
                          @click.native="clickCard(index)">
                          <el-row>
                            <el-col :span="7">
                              <el-avatar fit="fill" :src="tissue.WebUrl" @error="errorHandler">
                                <img :src="tissue.LocalAvatar" />
                              </el-avatar>
                            </el-col>
                            <el-col :span="17">
                              <div class="tissue-info">
                                <div class="tissue-info-name">
                                  {{ tissue.name }}
                                </div>
                                <div class="tissue-info-number">
                                  ({{ tissue.number }}cells)
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </el-card>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- Mobile-Clustering -->
        <div id="mobile-clustering" class="mobile-box-card-clustering">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-circle-nodes" />
            </span>
            <span> Clustering</span>
          </div>
          <div class="mobile-box-card-clustering-content">
            <div>
              <el-image :src="mouseImgUrl"></el-image>
              <div class="mobile-tissue-number">
                {{ clusteringCellNumber }} cells
              </div>
            </div>
            <div>
              <div>
                <font-awesome-icon icon="fa-solid fa-circle-info" />
                <span>
                  Select a tissue below to view cell type composition and hover
                  one to view the number of cells.</span>
              </div>
              <div v-for="(tissue, index) in tissueCardsInfo" :key="index">
                <el-card class="mobile-tissue-btns" :shadow="activeTissueID == index ? 'always' : 'hover'" :body-style="activeTissueID == index
                    ? 'padding: 12px 20px; background-color: #223468; color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)'
                    : 'padding: 12px 20px;'
                  " @mouseenter.native="onCard(tissue.number)" @mouseleave.native="offCard()"
                  @click.native="clickCard(index)">
                  <el-avatar fit="fill" :src="tissue.WebUrl" @error="errorHandler">
                    <img :src="tissue.LocalAvatar" />
                  </el-avatar>
                  <div>
                    <div>
                      {{ tissue.name }}
                    </div>
                    <div>({{ tissue.number }}cells)</div>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- Color mode -->
        <!-- <div id="color-mode" class="box-card-color-mode"> -->
        <div id="color-mode">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> Color mode</span>
          </div>
          <div class="color-mode-select">
            <el-select v-model="colorModeValue" @change="changeColorMode(activeTissueID, colorModeValue)">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="box-card-color-mode-content">
            <!-- <test></test> -->
            <div class="color-mode-plotly-image" v-loading="colorModePlotlyLoading">
              <iframe :src="colorModePlotly" scrolling="no" style="border: none" width="100%" height="100%"></iframe>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- Cell number -->
        <div id="cell-number">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> Cell number</span>
          </div>
          <div class="box-card-cell-number-content">
            <div class="cell-number-plotly-image" v-loading="cellNumberPlotlyLoading">
              <iframe :src="cellNumberPlotly" scrolling="no" style="border: none" width="100%" height="100%"></iframe>
            </div>
          </div>
        </div>
      </el-card>
      <!-- Paper citation link -->
      <el-card class="block-card-citation">
        <div class="box-card-item">
          <span class="box-card-item-icon">
            <font-awesome-icon icon="fa-solid fa-bookmark" />
          </span>
          <span> Paper Citation</span>
        </div>
        <div class="info-item-content">
          <div>Li, F., Xing, X., Jin, Q., Wang, X. M., Dai, P., Han, M., Shi, H., Zhang, Z., Shao, X.,
            Peng, Y., Zhu, Y., Xu, J., Li, D., Chen, Y., Wu, W., Wang, Q., Yu, C., Chen, L., Bai, F., & Gao, D. (2024).
            Sex differences orchestrated by androgens at single-cell resolution. Nature, 10.1038/s41586-024-07291-6.
            Advance online publication. <a href="https://doi.org/10.1038/s41586-024-07291-6" target="_blank" style="color: blue;
      ">https://doi.org/10.1038/s41586-024-07291-6</a>.</div>
        </div>
      </el-card>
      <!-- Global Visitors -->
      <!-- <el-card class="block-card" hidden> -->
      <el-card class="block-card-global">
        <div class="box-card-item">
          <span class="box-card-item-icon">
            <font-awesome-icon icon="fa-solid fa-bookmark" />
          </span>
          <span> Global Visitors</span>
        </div>
        <div class="info-item-content">
          <div class="global-visitors">
            <a href="https://clustrmaps.com/site/1bokk" title="Visit tracker" target="_blank"><img
                class="global-visitors-img"
                src="//clustrmaps.com/map_v2.png?cl=ffffff&w=700&t=tt&d=3HvuE041ZXwtDVfqjVZc_3GTiScO10GY09OznKX0AsM&co=2d78ad&ct=ffffff" /></a>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>



<script>
// import mouseImage from "/public/img/clustering-mouse.svg";
import mouseImage from "/public/img/clustering-mouse-plus2.0.svg";
import adiposeImage from "/public/img/tissues/adipose.svg";
import adrenalImage from "/public/img/tissues/adrenal.svg";
import boneMarrowImage from "/public/img/tissues/bone_marrow.svg";
import brainImage from "/public/img/tissues/brain.svg";
import colonImage from "/public/img/tissues/colon.svg";
import heartImage from "/public/img/tissues/heart.svg";
import intestineImage from "/public/img/tissues/intestine.svg";
import kidneyImage from "/public/img/tissues/kidney.svg";
import lacrimalImage from "/public/img/tissues/lacrimal.svg";
import liverImage from "/public/img/tissues/liver.svg";
import lungImage from "/public/img/tissues/lung.svg";
import pancreasImage from "/public/img/tissues/pancreas.svg";
import salivaryImage from "/public/img/tissues/salivary.svg";
import skeletalMuscleImage from "/public/img/tissues/skeletal_muscle.svg";
import spleenImage from "/public/img/tissues/spleen.svg";
import stomachImage from "/public/img/tissues/stomach.svg";
import thymusImage from "/public/img/tissues/thymus.svg";

export default {
  name: "Clustering",
  components: {
    //  test
  },
  data() {
    return {
      // url: "http://127.0.0.1:8000/",

      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      urls: [
        "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
        "https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg",
        "https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg",
        "https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg",
        "https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg",
        "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
        "https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg",
      ],
      mouseImgUrl: mouseImage,
      clusteringCellNumber: "2,337,431",  // 1,679,847  // 912,699
      activeTissueID: 0,
      tissueCardsInfo: [
        {
          WebUrl: adiposeImage,
          LocalAvatar: adiposeImage,
          name: "Adipose",
          number: "164,934", // 123,202 // 54,286
        },
        {
          WebUrl: adrenalImage,
          LocalAvatar: adrenalImage,
          name: "Adrenal",
          number: "117,245", // 77,271  // 33,859
        },
        {
          WebUrl: boneMarrowImage,
          LocalAvatar: boneMarrowImage,
          name: "Bone marrow",
          number: "123,086", // 83,157  // 40,253
        },
        {
          WebUrl: brainImage,
          LocalAvatar: brainImage,
          name: "Brain",
          number: "147,102", // 113,501 // 58,435
        },
        {
          WebUrl: colonImage,
          LocalAvatar: colonImage,
          name: "Colon",
          number: "87,676", // 78,481 // 30,414
        },
        {
          WebUrl: heartImage,
          LocalAvatar: heartImage,
          name: "Heart",
          number: "145,956", // 132,141 // 85,500
        },
        {
          WebUrl: intestineImage,
          LocalAvatar: intestineImage,
          name: "Intestine",
          number: "105,622", // 88,644  // 39,871
        },
        {
          WebUrl: kidneyImage,
          LocalAvatar: kidneyImage,
          name: "Kidney",
          number: "105,456", // 59,960  // 39,475
        },
        {
          WebUrl: lacrimalImage,
          LocalAvatar: lacrimalImage,
          name: "Lacrimal",
          number: "129,628", // 76,010  // 37,354
        },
        {
          WebUrl: liverImage,
          LocalAvatar: liverImage,
          name: "Liver",
          number: "156,101",  // 167,188  // 131,656
        },
        {
          WebUrl: lungImage,
          LocalAvatar: lungImage,
          name: "Lung",
          number: "262,741", // 146,427 // 80,436
        },
        {
          WebUrl: pancreasImage,
          LocalAvatar: pancreasImage,
          name: "Pancreas",
          number: "62,292", // 116,104  // 30,813
        },
        {
          WebUrl: salivaryImage,
          LocalAvatar: salivaryImage,
          name: "Salivary",
          number: "119,351", // 94,025  // 50,887
        },
        {
          WebUrl: skeletalMuscleImage,
          LocalAvatar: skeletalMuscleImage,
          name: "Skeletal muscle",
          number: "147,397", // 122,158 // 75,334
        },
        {
          WebUrl: spleenImage,
          LocalAvatar: spleenImage,
          name: "Spleen",
          number: "144,020", // 110,448 // 59,156
        },
        {
          WebUrl: stomachImage,
          LocalAvatar: stomachImage,
          name: "Stomach",
          number: "121,242", // 61,657  // 28,006
        },
        {
          WebUrl: thymusImage,
          LocalAvatar: thymusImage,
          name: "Thymus",
          number: "143,770", // 83,285  // 36,964
        },
      ],
      colorModeValue: 0,
      options: [
        {
          value: 0,
          label: "Colored by cell type",
        },
        {
          value: 1,
          label: "Colored by tissue",
        },
        // {
        //   value: 2,
        //   label: "Colored by condition",
        // },
      ],
      colorModePlotlyList: [
        [
          "adiposeColoredbyCellType",
          "adiposeColoredbyTissue",
          "adiposeColoredbyCondition",
        ],
        [
          "adrenalColoredbyCellType",
          "adrenalColoredbyTissue",
          "adrenalColoredbyCondition",
        ],
        [
          "boneMarrowColoredbyCellType",
          "boneMarrowColoredbyTissue",
          "boneMarrowColoredbyCondition",
        ],
        [
          "brainColoredbyCellType",
          "brainColoredbyTissue",
          "brainColoredbyCondition",
        ],
        [
          "colonColoredbyCellType",
          "colonColoredbyTissue",
          "colonColoredbyCondition",
        ],
        [
          "heartColoredbyCellType",
          "heartColoredbyTissue",
          "heartColoredbyCondition",
        ],
        [
          "intestineColoredbyCellType",
          "intestineColoredbyTissue",
          "intestineColoredbyCondition",
        ],
        [
          "kidneyColoredbyCellType",
          "kidneyColoredbyTissue",
          "kidneyColoredbyCondition",
        ],
        [
          "lacrimalColoredbyCellType",
          "lacrimalColoredbyTissue",
          "lacrimalColoredbyCondition",
        ],
        [
          "liverColoredbyCellType",
          "liverColoredbyTissue",
          "liverColoredbyCondition",
        ],
        [
          "lungColoredbyCellType",
          "lungColoredbyTissue",
          "lungColoredbyCondition",
        ],
        [
          "pancreasColoredbyCellType",
          "pancreasColoredbyTissue",
          "pancreasColoredbyCondition",
        ],
        [
          "salivaryColoredbyCellType",
          "salivaryColoredbyTissue",
          "salivaryColoredbyCondition",
        ],
        [
          "skeletalmuscleColoredbyCellType",
          "skeletalmuscleColoredbyTissue",
          "skeletalmuscleColoredbyCondition",
        ],
        [
          "spleenColoredbyCellType",
          "spleenColoredbyTissue",
          "spleenColoredbyCondition",
        ],
        [
          "stomachColoredbyCellType",
          "stomachColoredbyTissue",
          "stomachColoredbyCondition",
        ],
        [
          "thymusColoredbyCellType",
          "thymusColoredbyTissue",
          "thymusColoredbyCondition",
        ],
      ],
      cellNumberPlotlyList: [
        "adiposeCellNumber",
        "adrenalCellNumber",
        "boneMarrowCellNumber",
        "brainCellNumber",
        "colonCellNumber",
        "heartCellNumber",
        "intestineCellNumber",
        "kidneyCellNumber",
        "lacrimalCellNumber",
        "liverCellNumber",
        "lungCellNumber",
        "pancreasCellNumber",
        "salivaryCellNumber",
        "skeletalmuscleCellNumber",
        "spleenCellNumber",
        "stomachCellNumber",
        "thymusCellNumber",
      ],
      colorModePlotlyLoading: false,
      cellNumberPlotlyLoading: false,
      colorModePlotly: "",
      cellNumberPlotly: "",
    };
  },
  methods: {
    errorHandler() {
      return true;
    },
    onCard(number) {
      this.clusteringCellNumber = number;
    },
    offCard() {
      this.clusteringCellNumber = "2,337,431";  // 1,679,847  // 912,699
    },
    clickCard(cardID) {
      // console.log("点击了卡片");
      // console.log(cardID);
      this.activeTissueID = cardID;
      this.getColorModePlotly(this.colorModePlotlyList[cardID][0]);
      this.$el.querySelector("#color-mode").scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐
      });
      // this.getCellNumberPlotly(this.cellNumberPlotlyList[cardID]);
      this.getCellNumberPlotly("allTissuesCellNumber");
    },
    changeColorMode(a, b) {
      this.getColorModePlotly(this.colorModePlotlyList[a][b]);
    },
    async getColorModePlotly(file_name) {
      // console.log(this.axios.defaults.baseURL);
      let that = this;
      (this.colorModePlotlyLoading = true),
        await this.$axios
          .get("html/", {
            // that.url + "html/", {
            params: {
              htmlID: file_name,
            },
          })
          .then((res) => {
            that.colorModePlotly =
              that.axios.defaults.baseURL + "html/" + "?htmlID=" + file_name;
            // that.colorModePlotly = that.url + "html/" + "?htmlID=" + file_name;
            setTimeout(() => {
              that.colorModePlotlyLoading = false;
            }, 1000);
          })
          .catch((err) => {
            // console.log(err);
          });
    },
    async getCellNumberPlotly(file_name) {
      let that = this;
      (this.cellNumberPlotlyLoading = true),
        await this.$axios
          .get("html/", {
            // that.url + "html/", {
            params: {
              htmlID: file_name,
            },
          })
          .then((res) => {
            that.cellNumberPlotly =
              that.axios.defaults.baseURL + "html/" + "?htmlID=" + file_name;
            setTimeout(() => {
              that.cellNumberPlotlyLoading = false;
            }, 1000);
          })
          .catch((err) => {
            // console.log(err);
          });
    },
  },
  computed: {},
  mounted: function () {
    this.getColorModePlotly(
      this.colorModePlotlyList[this.activeTissueID][this.colorModeValue]
    );
    // this.getCellNumberPlotly(this.cellNumberPlotlyList[this.activeTissueID]);
    this.getCellNumberPlotly("allTissuesCellNumber");
  },
};
</script>

<style scoped>
/* banner */
.banner {
  width: 100%;
  height: 400px;
  /* background: url(https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg)
    center center; */
  background: url("@/assets/clustering-banner.jpg") center center;
  /* background-size: contain; */
  position: relative;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-text {
  width: 1320px;
  color: #fff;
  position: relative;
  top: 85px;
  left: 0;
  /* border: 1px solid yellow; */
}

.banner-text-title {
  font-size: 42px;
  font-weight: bold;
}

.banner-text-desc {
  margin-top: 5px;
  width: 800px;
  font-size: 19px;
  line-height: 1.4;
  text-align: justify;
  /* border: 1px solid red; */
}

/* carousel */
.carousel {
  text-align: center;
  background-color: #324766;
}

/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.block-card {
  width: 1320px;
  margin: 20px 0 0 0;
}

.block-card-citation {
  width: 1320px;
  margin: 20px 0 0 0;
}

.block-card-global {
  width: 1320px;
  margin: 20px 0 100px 0;
}

.box-card-item {
  font-size: 20px;
  font-weight: bold;
}

.box-card-item-icon {
  color: #223468;
}

/* clustering */
/* .box-card-clustering { */
/* height: 560px; */
/* border: 1px solid green; */
/* } */
.box-card-clustering-content {
  margin: 20px 0 0 0;
  /* border: 1px solid red; */
}

.clustering-left {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid yellow; */
}

.mouse-img {
  width: 460px;
  height: 460px;
}

.tissue-number {
  width: 460px;
  height: 24px;
  text-align: center;
  font-size: 24px;
  font-style: italic;
  font-family: "Open Sans", Arial, sans-serif;
  color: #223468;
}

.clustering-right {
  /* margin: 0 10px 10px; */
  padding-left: 30px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.tip {
  height: 24px;
  font-size: 14px;
}

.tissue-btns {
  height: 460px;
  /* border: 1px solid green; */
}

.tissue-btns .el-card {
  height: 66px;
  cursor: pointer;
  margin-top: 10px;
}

.tissue-info {
  height: 40px;
  text-align: center;
  /* border: 1px solid green; */
}

.tissue-info-name {
  font-size: 16px;
}

.tissue-info-number {
  font-size: 12px;
}

/* Color mode */
/* .box-card-color-mode { */
/* height: 700px; */
/* } */
.color-mode-select {
  margin: 20px 0 0 0;
  /* border: 1px solid red; */
}

.box-card-color-mode-content {
  /* height: 700px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* margin: 20px 0 0 0; */
  /* border: 1px solid red; */
}

.color-mode-plotly-image {
  width: 750px;
  height: 600px;
}

/* cell number */
/* .box-card-cell-number { */
/* height: 700px; */
/* } */
.box-card-cell-number-content {
  height: 700px;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.cell-number-plotly-image {
  height: 650px;
  width: 100%;
}

/* global-visitors */
.info-item-content {
  margin: 15px 0 25px 0;
  width: 100%;
  font-size: 16px;
  text-align: justify;
  line-height: 1.6;
  white-space: pre-wrap;
}

.global-visitors {
  text-align: center;
}

.global-visitors-img {
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

/* mobile样式 */
/* ***************************************************************** */
/* m-banner */
.mobile-banner-img {
  width: 100%;
}

.mobile-banner-text {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
}

.mobile-banner-text-desc {
  padding: 5%;
  background-color: white;
  margin-top: 5px;
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
}

/* m-clustering */
.mobile-box-card-clustering-content {
  text-align: center;
}

.mobile-tissue-number {
  font-size: 24px;
  font-style: italic;
  font-family: "Open Sans", Arial, sans-serif;
  color: #223468;
}

.mobile-tissue-btns {
  cursor: pointer;
  margin-top: 10px;
}

/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }

  .block-card-citation {
    width: 90%;
  }

  .block-card-global {
    width: 90%;
  }

  .banner-text {
    width: 90%;
  }

  .banner-text-desc {
    width: 100%;
  }
}

/* 1090px */
@media screen and (max-width: 1090px) {
  .box-card-clustering {
    display: none;
  }
}

@media screen and (min-width: 1090px) {
  .mobile-box-card-clustering {
    display: none;
  }
}

@media screen and (max-width: 860px) {
  .color-mode-plotly-image {
    width: 100%;
  }
}

/* 600px */
@media screen and (max-width: 600px) {
  .banner {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .mobile-banner {
    display: none;
  }
}</style>