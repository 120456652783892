<template>
  <div>
    <div class="block-container">
      <el-card class="block-card">
        <div class="box-card-gene-exp">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-people-roof" />
            </span>
            <span> About</span>
          </div>
        </div>
        <!-- About-Info -->
        <div id="about-info" class="box-card-about-info">
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> About CASA</span>
          </div>
          <div class="info-item-content">
            <span v-html="msg">
              <!-- {{ msg }} -->
            </span>
          </div>
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> About Our Team</span>
          </div>
          <div class="info-item-content">
            <span v-html="ourTeamMsg">
              <!-- {{ ourTeamMsg }} -->
            </span>
          </div>
          <div class="our-team-content">
            <!-- <el-carousel height="400px" :interval="5000" arrow="always"> -->
            <el-carousel :height="carouselHeight" :interval="5000" arrow="always">
              <el-carousel-item class="every-group-item" v-for="(list, index) in teamList" :key="index">
                <div class="person-item" v-for="(personList, index2) in list" :key="index2">
                  <img class="person-img" :src="personList.img" style="object-fit: fill" />
                  <div class="angles-up">
                    <font-awesome-icon icon="fa-solid fa-angles-up" />
                  </div>
                  <div class="person-card">
                    <div class="person-title">
                      <p style="
                              font-weight: bold;
                              font-size: 17px;
                              color: #223468;
                            ">
                        {{ personList.name }}
                      </p>
                    </div>
                    <div class="person-info">
                      <p>
                        <font-awesome-icon icon="fa-solid fa-user-tag" />
                        {{ personList.position }}
                      </p>
                      <p>
                        <font-awesome-icon icon="fa-solid fa-id-card" />
                        {{ personList.profile }}
                      </p>
                      <p>
                        <font-awesome-icon icon="fa-solid fa-envelope" />
                        {{ personList.email }}
                      </p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-clipboard-question" />
            </span>
            <span> Contact us</span>
          </div>
          <div class="info-item-content">
            <span v-html="contactMsg">
              <!-- {{ contactMsg }} -->
            </span>
          </div>
          <!-- <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> Global Visitors</span>
          </div>
          <div class="info-item-content">
            <div class="global-visitors">
              <a
                href="https://clustrmaps.com/site/1bokk"
                title="Visit tracker"
                target="_blank"
                ><img
                  class="global-visitors-img"
                  src="//clustrmaps.com/map_v2.png?cl=ffffff&w=700&t=tt&d=3HvuE041ZXwtDVfqjVZc_3GTiScO10GY09OznKX0AsM&co=2d78ad&ct=ffffff"
              /></a>
            </div>
          </div> -->
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import imgDongGao from "/public/img/about/about-DongGao.jpg";
import imgFanBai from "/public/img/about/about-FanBai.jpg";
import imgLuonanChen from "/public/img/about/about-LuonanChen.jpg";
import imgChenYu from "/public/img/about/about-ChenYu.jpg";
import imgFeiLi from "/public/img/about/about-FeiLi.jpg";
import imgXudongXing from "/public/img/about/about-XudongXing.jpg";
import imgQiqiJin from "/public/img/about/about-QiqiJin.jpg";
import imgXiangmingWang from "/public/img/about/about-XiangmingWang.jpg";
import imgPengfeiDai from "/public/img/about/about-PengfeiDai.jpg";
import imgMingHan from "/public/img/about/about-MingHan.jpg";
import imgZeZhang from "/public/img/about/about-ZeZhang.jpg";

export default {
  name: "About",
  data() {
    return {
      msg: "CASA is a single-cell transcriptomic atlas involving sex and androgens comprising a total of 2,337,431 cells from 17 tissues in the organism of <text style='font-style: italic'>Mus musculus</text>, which offers a deep molecular and cellular portrait of sex differences and their modulation by androgens at single-cell resolution. This atlas serves as an invaluable resource to help the science community to understand sex-biased complex traits and to treat sex-biased diseases.<br><br>CASA provides the customizable visualization and analyses for sex differences and their modulation by androgens at single-cell resolution.",
      ourTeamMsg:
        "This tool is developed by: <a href='http://cemcs.cas.cn/sourcedb_cemcs_cas/zw/jcqn/202109/t20210907_6198774.html' target='_blank'>Dong Gao Lab (CEMCS)</a><img src='https://pic.rmb.bdstatic.com/bjh/55c0e459e75644eddac50a39784e8d6a.png' style='height: 20px' alt=''></img>, <a href='http://www.bio.pku.edu.cn/homes/Index/news_cont_jl/16/6.html' target='_blank'>Fan Bai Lab (Peking University)</a><img src='https://pic.rmb.bdstatic.com/bjh/765c0b40dfc2fabcfc99f6a0028c1977.png' style='height: 20px' alt=''></img>, <a href='http://cemcs.cas.cn/sourcedb_cemcs_cas/zw/pi/202008/t20200823_5670080.html' target='_blank'>Luonan Chen Lab (CEMCS)</a><img src='https://pic.rmb.bdstatic.com/bjh/55c0e459e75644eddac50a39784e8d6a.png' style='height: 20px' alt=''></img> and <a href='http://yu-lab.szbl.ac.cn/' target='_blank'>Yu Lab (Shenzhen Bay Laboratory)</a><img src='https://www.szbl.ac.cn/dist/images/logo.png' style='height: 20px' alt=''></img>.",
      teamList: [
        [
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/ef3ebf7833427584c514741ef75fffa5.jpeg",
            img: imgDongGao,
            name: "Dong Gao",
            position: "Principal investigator",
            profile:
              "Omics and organoid technology to explore cell plasticity in development and disease.",
            email: "dong.gao@sibcb.ac.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/4f85260c64cd161e0744a747699d5362.jpeg",
            img: imgFanBai,
            name: "Fan Bai",
            position: "Principal investigator",
            profile:
              "Single-cell sequencing and cancer genomics. Bacterial physiology and antibiotic tolerance.",
            email: "fbai@pku.edu.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/e5bc4025775990ee800aea141a9696a4.jpeg",
            img: imgLuonanChen,
            name: "Luonan Chen",
            position: "Principal investigator",
            profile:
              "Computational biology and dynamic networks in complex diseases. Biological Big Data and Artificial Intelligence.",
            email: "lnchen@sibs.ac.cn",
          },
          {
            img: imgChenYu,
            name: "Chen Yu",
            position: "Principal investigator",
            profile:
              "The genomic foundation of cancer development and new gene therapies for genetic disease.",
            email: "yu@szbl.ac.cn",
          },
        ],
        [
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/5326d6f8b56a29b4ee001cb1fa869560.jpeg",
            img: imgFeiLi,
            name: "Fei Li",
            position: "Postdoctoral fellow",
            profile:
              "Cell plasticity and androgens’ function in human diseases.",
            email: "lifei6@sibcb.ac.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/845fd53d8632ef2dc4c817eb926455aa.jpeg",
            img: imgXudongXing,
            name: "Xudong Xing",
            position: "Postdoctoral fellow",
            profile:
              "Defining the basis of cancer and infectious disease by using combined cutting-edge technologies.",
            email: "xudonxing_bioinf@sina.com",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/bfce499e71fe5dcca1315225b7920996.jpeg",
            img: imgQiqiJin,
            name: "Qiqi Jin",
            position: "PhD student",
            profile: "Computational Systems Biology and Network Biology.",
            email: "jinqq@shanghaitech.edu.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/37c4db856eb870609412789b180630b4.jpeg",
            img: imgXiangmingWang,
            name: "Xiangming Wang",
            position: "Postdoctoral fellow",
            profile:
              "Single-cell sequencing and NGS technology to explore the pathogenesis of immune diseases and cancers.",
            email: "carolwxm@pku.edu.cn",
          },
        ],
        [
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/113dd19c99bfa0cb0ab5987f4b52fbd9.jpeg",
            img: imgPengfeiDai,
            name: "Pengfei Dai",
            position: "PhD student",
            profile:
              "Cell plasticity and androgens’ function in human diseases.",
            email: "daipengfei2019@sibcb.ac.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/801b9cd524c69d33241e5df494897ef7.jpeg",
            img: imgMingHan,
            name: "Ming Han",
            position: "PhD student",
            profile:
              "Cell plasticity and androgens’ function in human diseases.",
            email: "hanming2019@sibcb.ac.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/d96b55a45cb25edc04753f2f0f7c8715.jpeg",
            img: imgZeZhang,
            name: "Ze Zhang",
            position: "PhD student",
            profile: "Bioinformatics and Computational Systems Biology.",
            email: "zhangze21@mails.ucas.ac.cn",
          },
        ]
      ],
      contactMsg:
        "If any question about the CASA, please contact us: <a>dong.gao@sibcb.ac.cn</a>",
      // 定义走马灯的高度
      carouselHeight: "400px",
      // 页面宽度
      windowWidth: 1920,
    };
  },
  methods: {
    errorHandler() {
      return true;
    },
  },
  mounted() {
    var that = this;
    that.windowWidth = document.documentElement.clientWidth; // 宽
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        that.windowWidth = document.documentElement.clientWidth; // 宽
      })();
    };
  },
  watch: {
    windowWidth(val) {
      let that = this;
      if (val < 1080) {
        that.carouselHeight = "1680px"; // 1200px
      } else {
        that.carouselHeight = "420px";
      }
      // console.log("实时屏幕宽度：", val, that.windowHeight);
    },
  },
};
</script>

<style scoped>
/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-card {
  width: 1320px;
  margin: 20px 0 100px 0;
}

.box-card-item {
  font-size: 20px;
  font-weight: bold;
}

.box-card-item-icon {
  color: #223468;
}

/* about-info */
.box-card-about-info {
  margin: 15px 0 20px 0;
  width: 100%;
  /* height: 1000px; */
  /* padding: 20px; */
  /* border: 1px solid red; */
  /* background-color: #f4f6fc; */
}

.info-item {
  font-size: 18px;
  font-weight: bold;
}

.info-item-icon {
  color: #223468;
}

.info-item-content {
  margin: 15px 0 25px 0;
  width: 100%;
  font-size: 16px;
  text-align: justify;
  line-height: 1.6;
  white-space: pre-wrap;
}

/* About Our Team */
.our-team-content {
  margin-bottom: 30px;
  text-align: center;
}

.el-carousel {
  width: 100%;
}

.every-group-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid green; */
}

.person-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.person-img {
  width: 130px;
  height: 150px;
  /* margin-right: 20px; */
  /* cursor: pointer; */
}

.angles-up {
  margin: 10px 0 10px 0;
  font-size: 17px;
  color: #223468;
}

.person-card {
  padding: 14px;
  width: 284px;
  font-size: 14px;
  line-height: 24px;
  color: #9a9a9a;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  /* border: 1px solid yellow; */
}

.person-title {
  margin-bottom: 10px;
}

.global-visitors {
  text-align: center;
}

.global-visitors-img {
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }
}

@media screen and (max-width: 1080px) {
  .every-group-item {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .person-card {
    width: 90%;
  }
}
</style>