<template>
  <div>
    <div class="block-container">
      <el-card class="block-card">
        <div class="box-card-deg">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-code-compare" />
            </span>
            <span> DEG (Differentially expressed gene)</span>
          </div>
        </div>
        <div class="select-container">
          <div class="select-components">
            <div class="select-title">Tissue</div>
            <div class="select-content">
              <el-select v-model="tissueValue" style="width: 100%" filterable @change="changeTissue(tissueValue)">
                <el-option v-for="item in tissueOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Comparison</div>
            <div class="select-content">
              <el-select v-model="comparisonValue" style="width: 100%" @change="
                filterTable(
                  tissueValue,
                  comparisonValue,
                  cellTypeValue,
                  changeValue
                )
                ">
                <el-option v-for="item in comparisonOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Cell type</div>
            <div class="select-content">
              <el-select v-model="cellTypeValue" style="width: 100%" filterable @change="
                filterTable(
                  tissueValue,
                  comparisonValue,
                  cellTypeValue,
                  changeValue
                )
                ">
                <el-option v-for="item in cellTypeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Change</div>
            <div class="select-content">
              <el-select v-model="changeValue" style="width: 100%" @change="
                filterTable(
                  tissueValue,
                  comparisonValue,
                  cellTypeValue,
                  changeValue
                )
                ">
                <el-option v-for="item in changeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="box-card-table">
          <Table border stripe :loading="tableLoading" :columns="tableTitleColumns" :data="tableData" size="default"
            ref="table"></Table>
          <div class="table-page">
            <Page :total="total" @on-change="changePage" :current="pageNum" :page-size="pageSize"
              @on-page-size-change="handlePageSize" show-total show-elevator show-sizer
              :page-size-opts="[10, 20, 50, 100, 200, 500]" />
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "DEG",
  data() {
    return {
      // url: "http://127.0.0.1:8000/",

      tissueValue: "Adipose", // adipose
      tissueOptions: [
        {
          value: "Adipose", // adipose
          label: "Adipose",
        },
        {
          value: "Adrenal", // adrenal
          label: "Adrenal",
        },
        {
          value: "Bonemarrow", // bone_marrow
          label: "Bone marrow",
        },
        {
          value: "Brain", // brain
          label: "Brain",
        },
        {
          value: "Colon", // colon
          label: "Colon",
        },
        {
          value: "Heart", // heart
          label: "Heart",
        },
        {
          value: "Intestine", // intestine
          label: "Intestine",
        },
        {
          value: "Kidney",  // kidney
          label: "Kidney",
        },
        {
          value: "Lacrimal",  // lacrimal
          label: "Lacrimal",
        },
        {
          value: "Liver", // liver
          label: "Liver",
        },
        {
          value: "Lung",  // lung
          label: "Lung",
        },
        {
          value: "Pancreas",  // pancreas
          label: "Pancreas",
        },
        {
          value: "Salivary",  // salivary
          label: "Salivary",
        },
        {
          value: "Skeletalmuscle",  // skeletal_muscle
          label: "Skeletal muscle",
        },
        {
          value: "Spleen",  // spleen
          label: "Spleen",
        },
        {
          value: "Stomach", // stomach
          label: "Stomach",
        },
        {
          value: "Thymus",  // thymus
          label: "Thymus",
        },
      ],
      comparisonValue: "all",
      comparisonOptions: [
        {
          value: "all",
          label: "All comparisons",
        },
        {
          value: "FDvsFS",
          label: "FDvsFS",
        },
        {
          value: "MCvsMS",
          label: "MCvsMS",
        },
        {
          value: "MSvsFS",
          label: "MSvsFS",
        },
      ],
      cellTypeOptionsDict: {
        Adipose: [{ value: "all", label: "All cell types" },
        { value: "Adipocyte", label: "Adipocyte" },
        { value: "Adipose_stem_cell", label: "Adipose_stem_cell" },
        { value: "B_cell", label: "B_cell" },
        { value: "DC", label: "DC" },
        { value: "EC_Lym", label: "EC_Lym" },
        { value: "Endothelial_1", label: "Endothelial_1" },
        { value: "Endothelial_2", label: "Endothelial_2" },
        { value: "Endothelial_3", label: "Endothelial_3" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "ILC2", label: "ILC2" },
        { value: "Macrophage_1", label: "Macrophage_1" },
        { value: "Macrophage_2", label: "Macrophage_2" },
        { value: "Macrophage_3", label: "Macrophage_3" },
        { value: "Mast_cell", label: "Mast_cell" },
        { value: "Mesothelial_cell", label: "Mesothelial_cell" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Neutrophil", label: "Neutrophil" },
        { value: "Preadipocyte_1", label: "Preadipocyte_1" },
        { value: "Preadipocyte_2", label: "Preadipocyte_2" },
        { value: "Preadipocyte_3", label: "Preadipocyte_3" },
        { value: "T_cell", label: "T_cell" },
        { value: "Treg", label: "Treg" },
        { value: "cDC1", label: "cDC1" }],
        Adrenal: [{ value: "all", label: "All cell types" },
        { value: "B_cell", label: "B_cell" },
        { value: "Chromaffin_cell", label: "Chromaffin_cell" },
        { value: "Cortex_1", label: "Cortex_1" },
        { value: "Cortex_2", label: "Cortex_2" },
        { value: "Cortex_3", label: "Cortex_3" },
        { value: "Endothelial_1", label: "Endothelial_1" },
        { value: "Endothelial_2", label: "Endothelial_2" },
        { value: "Endothelial_3", label: "Endothelial_3" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "Glial_cell", label: "Glial_cell" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Monocyte", label: "Monocyte" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Smooth_muscle", label: "Smooth_muscle" },
        { value: "T_cell", label: "T_cell" },
        { value: "cDC", label: "cDC" },
        { value: "pDC", label: "pDC" }],
        Bonemarrow: [{ value: "all", label: "All cell types" },
        { value: "Basophil", label: "Basophil" },
        { value: "DC", label: "DC" },
        { value: "Immature_B_1", label: "Immature_B_1" },
        { value: "Immature_B_2", label: "Immature_B_2" },
        { value: "Immature_B_3", label: "Immature_B_3" },
        { value: "Immature_B_4", label: "Immature_B_4" },
        { value: "Mature_B", label: "Mature_B" },
        { value: "Monocyte", label: "Monocyte" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Neutrophil_1", label: "Neutrophil_1" },
        { value: "Neutrophil_2", label: "Neutrophil_2" },
        { value: "Neutrophil_3", label: "Neutrophil_3" },
        { value: "Neutrophil_4", label: "Neutrophil_4" },
        { value: "Neutrophil_5", label: "Neutrophil_5" },
        { value: "Plasma", label: "Plasma" },
        { value: "T_cell", label: "T_cell" }],
        Brain: [{ value: "all", label: "All cell types" },
        { value: "Astrocyte_1", label: "Astrocyte_1" },
        { value: "Astrocyte_2", label: "Astrocyte_2" },
        { value: "B_cell", label: "B_cell" },
        { value: "Endothelial", label: "Endothelial" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "Hypothalamic_ependymal", label: "Hypothalamic_ependymal" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Meningeal", label: "Meningeal" },
        { value: "Microglia_1", label: "Microglia_1" },
        { value: "Microglia_2", label: "Microglia_2" },
        { value: "Neuron", label: "Neuron" },
        { value: "Neutrophil", label: "Neutrophil" },
        {
          value: "Olfactory_ensheathing_cell",
          label: "Olfactory_ensheathing_cell"
        },
        { value: "Oligodendrocyte_1", label: "Oligodendrocyte_1" },
        { value: "Oligodendrocyte_2", label: "Oligodendrocyte_2" },
        { value: "Oligodendrocyte_3", label: "Oligodendrocyte_3" },
        { value: "Pan_GABAergic", label: "Pan_GABAergic" },
        { value: "Pericyte", label: "Pericyte" },
        { value: "Schwann", label: "Schwann" },
        { value: "T_NK_cell", label: "T_NK_cell" }],
        Colon: [{ value: "all", label: "All cell types" },
        { value: "B_cell", label: "B_cell" },
        { value: "Enterocyte_1", label: "Enterocyte_1" },
        { value: "Enterocyte_2", label: "Enterocyte_2" },
        { value: "Enteroendocrine", label: "Enteroendocrine" },
        { value: "Goblet", label: "Goblet" },
        { value: "TA", label: "TA" },
        { value: "T_NK_cell", label: "T_NK_cell" },
        { value: "Tuft", label: "Tuft" }],
        Heart: [{ value: "all", label: "All cell types" },
        { value: "B_cell", label: "B_cell" },
        { value: "EC_Lym", label: "EC_Lym" },
        { value: "Endothelial", label: "Endothelial" },
        { value: "Epicardial_cell", label: "Epicardial_cell" },
        { value: "Fibroblast_1", label: "Fibroblast_1" },
        { value: "Fibroblast_2", label: "Fibroblast_2" },
        { value: "Fibroblast_3", label: "Fibroblast_3" },
        { value: "Fibroblast_4", label: "Fibroblast_4" },
        { value: "Fibroblast_5", label: "Fibroblast_5" },
        { value: "ILC2", label: "ILC2" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Monocyte", label: "Monocyte" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Neutrophil", label: "Neutrophil" },
        { value: "Pericyte", label: "Pericyte" },
        { value: "Schwann", label: "Schwann" },
        { value: "Smooth_muscle", label: "Smooth_muscle" },
        { value: "T_cell", label: "T_cell" },
        { value: "Treg", label: "Treg" }],
        Intestine: [{ value: "all", label: "All cell types" },
        { value: "B_cell_1", label: "B_cell_1" },
        { value: "B_cell_2", label: "B_cell_2" },
        { value: "Enterocyte_1", label: "Enterocyte_1" },
        { value: "Enterocyte_2", label: "Enterocyte_2" },
        { value: "Enteroendocrine", label: "Enteroendocrine" },
        { value: "Goblet", label: "Goblet" },
        { value: "Paneth", label: "Paneth" },
        { value: "TA", label: "TA" },
        { value: "T_NK_cell_1", label: "T_NK_cell_1" },
        { value: "T_NK_cell_2", label: "T_NK_cell_2" },
        { value: "Tuft", label: "Tuft" }],
        Kidney: [{ value: "all", label: "All cell types" },
        { value: "B_cell", label: "B_cell" },
        {
          value: "Collecting_duct_intercalated",
          label: "Collecting_duct_intercalated"
        },
        {
          value: "Collecting_duct_principal_1",
          label: "Collecting_duct_principal_1"
        },
        {
          value: "Collecting_duct_principal_2",
          label: "Collecting_duct_principal_2"
        },
        { value: "Collecting_transitional", label: "Collecting_transitional" },
        { value: "Connecting_tubule", label: "Connecting_tubule" },
        { value: "Descending_thin_lmb", label: "Descending_thin_lmb" },
        { value: "Distal_convoluted_tubule", label: "Distal_convoluted_tubule" },
        { value: "Endothelial_1", label: "Endothelial_1" },
        { value: "Endothelial_2", label: "Endothelial_2" },
        { value: "Endothelial_3", label: "Endothelial_3" },
        { value: "Loop_of_Henle_cell", label: "Loop_of_Henle_cell" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Mesangial_cell_1", label: "Mesangial_cell_1" },
        { value: "Mesangial_cell_2", label: "Mesangial_cell_2" },
        { value: "Monocyte_1", label: "Monocyte_1" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Neutrophil", label: "Neutrophil" },
        { value: "Podocyte_cell", label: "Podocyte_cell" },
        {
          value: "Proximal_convoluted_tubule_1",
          label: "Proximal_convoluted_tubule_1"
        },
        {
          value: "Proximal_convoluted_tubule_2",
          label: "Proximal_convoluted_tubule_2"
        },
        {
          value: "Proximal_convoluted_tubule_3",
          label: "Proximal_convoluted_tubule_3"
        },
        {
          value: "Proximal_straight_tubule_1",
          label: "Proximal_straight_tubule_1"
        },
        {
          value: "Proximal_straight_tubule_2",
          label: "Proximal_straight_tubule_2"
        },
        { value: "Smooth_muscle", label: "Smooth_muscle" },
        { value: "T_cell", label: "T_cell" }],
        Lacrimal: [{ value: "all", label: "All cell types" },
        { value: "Acinar_1", label: "Acinar_1" },
        { value: "Acinar_2", label: "Acinar_2" },
        { value: "Ductal", label: "Ductal" },
        { value: "Endothelial", label: "Endothelial" },
        { value: "Epithelial", label: "Epithelial" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "ILC2", label: "ILC2" },
        { value: "Macrophage_1", label: "Macrophage_1" },
        { value: "Macrophage_2", label: "Macrophage_2" },
        { value: "Myoepithelial", label: "Myoepithelial" },
        { value: "Smooth_muscle", label: "Smooth_muscle" },
        { value: "T_NK_cell", label: "T_NK_cell" },
        { value: "T_cell", label: "T_cell" }],
        Liver: [{ value: "all", label: "All cell types" },
        { value: "B_cell", label: "B_cell" },
        { value: "Cholangiocyte", label: "Cholangiocyte" },
        { value: "DC", label: "DC" },
        { value: "EC_Lym", label: "EC_Lym" },
        { value: "Endothelial_1", label: "Endothelial_1" },
        { value: "Endothelial_2", label: "Endothelial_2" },
        { value: "Hepatocyte_1", label: "Hepatocyte_1" },
        { value: "Hepatocyte_2", label: "Hepatocyte_2" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Mesenchyme", label: "Mesenchyme" },
        { value: "Neutrophil", label: "Neutrophil" },
        { value: "T_cell", label: "T_cell" }],
        Lung: [{ value: "all", label: "All cell types" },
        { value: "AT1_cell", label: "AT1_cell" },
        { value: "AT2_cell", label: "AT2_cell" },
        { value: "Active_CD4_T", label: "Active_CD4_T" },
        { value: "Active_CD8_T", label: "Active_CD8_T" },
        { value: "B_cell", label: "B_cell" },
        { value: "Basal", label: "Basal" },
        { value: "Ciliated_cell", label: "Ciliated_cell" },
        { value: "Club_cell", label: "Club_cell" },
        { value: "Endothelial_1", label: "Endothelial_1" },
        { value: "Endothelial_2", label: "Endothelial_2" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "ILC2", label: "ILC2" },
        { value: "Kulchitsky_cell", label: "Kulchitsky_cell" },
        { value: "Macrophage_1", label: "Macrophage_1" },
        { value: "Macrophage_2", label: "Macrophage_2" },
        { value: "Mast_cell", label: "Mast_cell" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Naive_CD4_T", label: "Naive_CD4_T" },
        { value: "Naive_CD8_T", label: "Naive_CD8_T" },
        { value: "Neutrophil", label: "Neutrophil" },
        { value: "Smooth_muscle", label: "Smooth_muscle" }],
        Pancreas: [{ value: "all", label: "All cell types" },
        { value: "Acinar", label: "Acinar" },
        { value: "B_cell", label: "B_cell" },
        { value: "Ductal_1", label: "Ductal_1" },
        { value: "Ductal_2", label: "Ductal_2" },
        { value: "Endocrine_alpha", label: "Endocrine_alpha" },
        { value: "Endocrine_beta", label: "Endocrine_beta" },
        { value: "Endocrine_delta", label: "Endocrine_delta" },
        { value: "Endothelial", label: "Endothelial" },
        { value: "Fibroblast_1", label: "Fibroblast_1" },
        { value: "Fibroblast_2", label: "Fibroblast_2" },
        { value: "ILC2", label: "ILC2" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Pancreatic_polypeptide", label: "Pancreatic_polypeptide" },
        { value: "Smooth_muscle", label: "Smooth_muscle" },
        { value: "T_cell", label: "T_cell" }],
        Salivary: [{ value: "all", label: "All cell types" },
        { value: "Basal_1", label: "Basal_1" },
        { value: "Basal_2", label: "Basal_2" },
        { value: "EC_Lym", label: "EC_Lym" },
        { value: "Endothelial_1", label: "Endothelial_1" },
        { value: "Endothelial_2", label: "Endothelial_2" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "ILC2", label: "ILC2" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Myoepithelial", label: "Myoepithelial" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Neuron", label: "Neuron" },
        { value: "SLG_Acinar", label: "SLG_Acinar" },
        { value: "SLG_Ductal", label: "SLG_Ductal" },
        { value: "SMG_Acinar", label: "SMG_Acinar" },
        { value: "SMG_Ductal_1", label: "SMG_Ductal_1" },
        { value: "SMG_Ductal_2", label: "SMG_Ductal_2" },
        { value: "SMG_Ductal_3", label: "SMG_Ductal_3" },
        { value: "SMG_Ductal_4", label: "SMG_Ductal_4" },
        { value: "SMG_Ductal_5", label: "SMG_Ductal_5" },
        { value: "Smooth_muscle", label: "Smooth_muscle" }],
        Skeletalmuscle: [{ value: "all", label: "All cell types" },
        { value: "B_cell_1", label: "B_cell_1" },
        { value: "B_cell_2", label: "B_cell_2" },
        { value: "EC_Lym", label: "EC_Lym" },
        { value: "Endothelial_1", label: "Endothelial_1" },
        { value: "Endothelial_2", label: "Endothelial_2" },
        { value: "Endothelial_3", label: "Endothelial_3" },
        { value: "Epithelial", label: "Epithelial" },
        { value: "Fibro_adipo_progenitor", label: "Fibro_adipo_progenitor" },
        { value: "Fibroblast_1", label: "Fibroblast_1" },
        { value: "Fibroblast_2", label: "Fibroblast_2" },
        { value: "Fibroblast_3", label: "Fibroblast_3" },
        { value: "Glial_cell", label: "Glial_cell" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Monocyte", label: "Monocyte" },
        { value: "Muscle_stem_cell", label: "Muscle_stem_cell" },
        { value: "Neutrophil", label: "Neutrophil" },
        { value: "Schwann", label: "Schwann" },
        { value: "Skeletal_muscle", label: "Skeletal_muscle" },
        { value: "T_cell_1", label: "T_cell_1" },
        { value: "T_cell_2", label: "T_cell_2" },
        { value: "Tenocyte_cell", label: "Tenocyte_cell" },
        { value: "Treg", label: "Treg" }],
        Spleen: [{ value: "all", label: "All cell types" },
        { value: "Active_CD4_T", label: "Active_CD4_T" },
        { value: "Active_CD8_T", label: "Active_CD8_T" },
        { value: "B_cell_1", label: "B_cell_1" },
        { value: "B_cell_2", label: "B_cell_2" },
        { value: "B_cell_3", label: "B_cell_3" },
        { value: "B_cell_4", label: "B_cell_4" },
        { value: "Macrophage_1", label: "Macrophage_1" },
        { value: "Macrophage_2", label: "Macrophage_2" },
        { value: "Macrophage_3", label: "Macrophage_3" },
        { value: "Macrophage_4", label: "Macrophage_4" },
        { value: "Memory_CD4_T", label: "Memory_CD4_T" },
        { value: "NK_cell", label: "NK_cell" },
        { value: "Naive_CD4_T", label: "Naive_CD4_T" },
        { value: "Naive_CD8_T", label: "Naive_CD8_T" },
        { value: "Neutrophil", label: "Neutrophil" },
        { value: "Plasma", label: "Plasma" },
        { value: "Regulatory_CD4_T", label: "Regulatory_CD4_T" },
        { value: "gdT_cell", label: "gdT_cell" }],
        Stomach: [{ value: "all", label: "All cell types" },
        { value: "Chief_cell", label: "Chief_cell" },
        { value: "Endothelial", label: "Endothelial" },
        { value: "Enterochromaffin_like", label: "Enterochromaffin_like" },
        { value: "Epithelial", label: "Epithelial" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "Foveolar_cell_1", label: "Foveolar_cell_1" },
        { value: "Foveolar_cell_2", label: "Foveolar_cell_2" },
        { value: "Foveolar_cell_3", label: "Foveolar_cell_3" },
        { value: "Foveolar_cell_4", label: "Foveolar_cell_4" },
        { value: "G_D_cell", label: "G_D_cell" },
        { value: "ILC2", label: "ILC2" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Mast_cell", label: "Mast_cell" },
        { value: "Mucous_cell", label: "Mucous_cell" },
        { value: "Neuroendocrine", label: "Neuroendocrine" },
        { value: "Parietal_cell", label: "Parietal_cell" },
        { value: "Tuft_cell", label: "Tuft_cell" }],
        Thymus: [{ value: "all", label: "All cell types" },
        { value: "B_cell", label: "B_cell" },
        { value: "DNpro", label: "DNpro" },
        { value: "DPblast", label: "DPblast" },
        { value: "DPres_1", label: "DPres_1" },
        { value: "DPres_2", label: "DPres_2" },
        { value: "DPsels", label: "DPsels" },
        { value: "Endothelial", label: "Endothelial" },
        { value: "Fibroblast", label: "Fibroblast" },
        { value: "Macrophage", label: "Macrophage" },
        { value: "Mito_high", label: "Mito_high" },
        { value: "NKT_1", label: "NKT_1" },
        { value: "NKT_2", label: "NKT_2" },
        { value: "Plasma", label: "Plasma" },
        { value: "SP", label: "SP" },
        { value: "cDC", label: "cDC" },
        { value: "pDC", label: "pDC" }]
      },
      // cellTypeOptionsDict: {
      //   adipose: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Adipocyte", label: "Adipocyte" },
      //     { value: "Adipose_stem_cell_1", label: "Adipose_stem_cell_1" },
      //     { value: "Adipose_stem_cell_2", label: "Adipose_stem_cell_2" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Dendritic_cell", label: "Dendritic_cell" },
      //     { value: "Differentiared_ASC", label: "Differentiared_ASC" },
      //     { value: "Effector_CD4", label: "Effector_CD4" },
      //     { value: "Endothelial", label: "Endothelial" },
      //     { value: "ILC2", label: "ILC2" },
      //     { value: "ILC2_Cd4", label: "ILC2_Cd4" },
      //     { value: "Macrophage_1", label: "Macrophage_1" },
      //     { value: "Macrophage_2", label: "Macrophage_2" },
      //     { value: "Macrophage_3", label: "Macrophage_3" },
      //     { value: "Macrophage_4", label: "Macrophage_4" },
      //     { value: "Mesothelial_cell", label: "Mesothelial_cell" },
      //     { value: "MoDC", label: "MoDC" },
      //     { value: "Monocyte", label: "Monocyte" },
      //     { value: "Monocyte_Macrophage", label: "Monocyte_Macrophage" },
      //     { value: "NKT_cell", label: "NKT_cell" },
      //     { value: "NK_cell", label: "NK_cell" },
      //     { value: "Naive_T", label: "Naive_T" },
      //     { value: "Neutrophil", label: "Neutrophil" },
      //     { value: "Preadipocyte_1", label: "Preadipocyte_1" },
      //     { value: "Preadipocyte_2", label: "Preadipocyte_2" },
      //     { value: "Preadipocyte_3", label: "Preadipocyte_3" },
      //     { value: "Proli_DC", label: "Proli_DC" },
      //     { value: "Proli_M", label: "Proli_M" },
      //     { value: "Proli_T", label: "Proli_T" },
      //     { value: "Th17", label: "Th17" },
      //   ],
      //   adrenal: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Adipocyte", label: "Adipocyte" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Cortex_1", label: "Cortex_1" },
      //     { value: "Cortex_2", label: "Cortex_2" },
      //     { value: "Cortex_3", label: "Cortex_3" },
      //     { value: "Endothelial_1", label: "Endothelial_1" },
      //     { value: "Endothelial_2", label: "Endothelial_2" },
      //     { value: "Endothelial_3", label: "Endothelial_3" },
      //     { value: "Endothelial_4", label: "Endothelial_4" },
      //     { value: "Fibroblast_1", label: "Fibroblast_1" },
      //     { value: "Fibroblast_2", label: "Fibroblast_2" },
      //     { value: "Krt19_positive", label: "Krt19_positive" },
      //     { value: "Macrophage_1", label: "Macrophage_1" },
      //     { value: "Macrophage_2", label: "Macrophage_2" },
      //     { value: "NK_cell", label: "NK_cell" },
      //     { value: "Smooth_muscle", label: "Smooth_muscle" },
      //     { value: "T_cell", label: "T_cell" },
      //   ],
      //   bone_marrow: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Basophil", label: "Basophil" },
      //     { value: "Eosinophil", label: "Eosinophil" },
      //     { value: "Immature_B_1", label: "Immature_B_1" },
      //     { value: "Immature_B_2", label: "Immature_B_2" },
      //     { value: "Immature_B_3", label: "Immature_B_3" },
      //     { value: "Macrophage", label: "Macrophage" },
      //     { value: "Mature_B", label: "Mature_B" },
      //     { value: "Monocyte_1", label: "Monocyte_1" },
      //     { value: "Monocyte_2", label: "Monocyte_2" },
      //     { value: "Neutrophil_1", label: "Neutrophil_1" },
      //     { value: "Neutrophil_2", label: "Neutrophil_2" },
      //     { value: "Neutrophil_3", label: "Neutrophil_3" },
      //     { value: "Neutrophil_4", label: "Neutrophil_4" },
      //     { value: "T_cell", label: "T_cell" },
      //   ],
      //   brain: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Astrocyte_1", label: "Astrocyte_1" },
      //     { value: "Astrocyte_2", label: "Astrocyte_2" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Endothelial", label: "Endothelial" },
      //     { value: "Fibroblast", label: "Fibroblast" },
      //     { value: "Hypothalamic_ependymal", label: "Hypothalamic_ependymal" },
      //     { value: "Macrophage", label: "Macrophage" },
      //     { value: "Meningeal", label: "Meningeal" },
      //     { value: "Microglia_1", label: "Microglia_1" },
      //     { value: "Microglia_2", label: "Microglia_2" },
      //     { value: "Monocyte", label: "Monocyte" },
      //     { value: "Neuron", label: "Neuron" },
      //     { value: "OECs", label: "OECs" },
      //     { value: "Oligodendrocyte_1", label: "Oligodendrocyte_1" },
      //     { value: "Oligodendrocyte_2", label: "Oligodendrocyte_2" },
      //     { value: "Pan_GABAergic", label: "Pan_GABAergic" },
      //     { value: "Pericyte", label: "Pericyte" },
      //     { value: "Schwann", label: "Schwann" },
      //     { value: "T_NK", label: "T_NK" },
      //   ],
      //   colon: [
      //     { value: "all", label: "All cell types" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Enterocyte_1", label: "Enterocyte_1" },
      //     { value: "Enterocyte_2", label: "Enterocyte_2" },
      //     { value: "Enterocyte_3", label: "Enterocyte_3" },
      //     { value: "Enterocyte_4", label: "Enterocyte_4" },
      //     { value: "Enterocyte_5", label: "Enterocyte_5" },
      //     { value: "Enteroendocrine", label: "Enteroendocrine" },
      //     { value: "Fibroblast", label: "Fibroblast" },
      //     { value: "Goblet_1", label: "Goblet_1" },
      //     { value: "Goblet_2", label: "Goblet_2" },
      //     { value: "T_NK", label: "T_NK" },
      //     { value: "Tuft_cell", label: "Tuft_cell" },
      //   ],
      //   heart: [
      //     { value: "all", label: "All cell types" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Effector_T", label: "Effector_T" },
      //     { value: "Endocardial_1", label: "Endocardial_1" },
      //     { value: "Endocardial_2", label: "Endocardial_2" },
      //     { value: "Endocardial_3", label: "Endocardial_3" },
      //     { value: "Endothelial", label: "Endothelial" },
      //     { value: "Epicardial_1", label: "Epicardial_1" },
      //     { value: "Epicardial_2", label: "Epicardial_2" },
      //     { value: "Fibroblast_1", label: "Fibroblast_1" },
      //     { value: "Fibroblast_2", label: "Fibroblast_2" },
      //     { value: "Fibroblast_3", label: "Fibroblast_3" },
      //     { value: "Fibroblast_4", label: "Fibroblast_4" },
      //     { value: "Fibroblast_5", label: "Fibroblast_5" },
      //     { value: "Fibroblast_6", label: "Fibroblast_6" },
      //     { value: "Fibroblast_7", label: "Fibroblast_7" },
      //     { value: "ILC2", label: "ILC2" },
      //     { value: "Macrophage_1", label: "Macrophage_1" },
      //     { value: "Macrophage_2", label: "Macrophage_2" },
      //     { value: "MoDC", label: "MoDC" },
      //     { value: "NK_cell", label: "NK_cell" },
      //     { value: "Naive_T", label: "Naive_T" },
      //     { value: "Neutrophil", label: "Neutrophil" },
      //     { value: "Pericyte", label: "Pericyte" },
      //     { value: "Schwann", label: "Schwann" },
      //     { value: "Smooth_muscle", label: "Smooth_muscle" },
      //   ],
      //   intestine: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Enterocyte_1", label: "Enterocyte_1" },
      //     { value: "Enterocyte_2", label: "Enterocyte_2" },
      //     { value: "Enterocyte_3", label: "Enterocyte_3" },
      //     { value: "Enterocyte_4", label: "Enterocyte_4" },
      //     { value: "Enteroendocrine", label: "Enteroendocrine" },
      //     { value: "Goblet", label: "Goblet" },
      //     { value: "Monocyte_B", label: "Monocyte_B" },
      //     { value: "Paneth", label: "Paneth" },
      //     { value: "T_NK_1", label: "T_NK_1" },
      //     { value: "T_NK_2", label: "T_NK_2" },
      //     { value: "Tuft_cell", label: "Tuft_cell" },
      //   ],
      //   kidney: [
      //     { value: "all", label: "All cell types" },
      //     { value: "B_cell", label: "B_cell" },
      //     {
      //       value: "Collecting_duct_intercalated_cell_1",
      //       label: "Collecting_duct_intercalated_cell_1",
      //     },
      //     {
      //       value: "Collecting_duct_intercalated_cell_2",
      //       label: "Collecting_duct_intercalated_cell_2",
      //     },
      //     {
      //       value: "Collecting_duct_principal_cell_1",
      //       label: "Collecting_duct_principal_cell_1",
      //     },
      //     {
      //       value: "Collecting_duct_principal_cell_2",
      //       label: "Collecting_duct_principal_cell_2",
      //     },
      //     {
      //       value: "Collecting_transitional_cell",
      //       label: "Collecting_transitional_cell",
      //     },
      //     { value: "Connecting_tubule", label: "Connecting_tubule" },
      //     { value: "Dendritic_cell", label: "Dendritic_cell" },
      //     { value: "Descending_thin_limb", label: "Descending_thin_limb" },
      //     {
      //       value: "Distal_convoluted_tubule",
      //       label: "Distal_convoluted_tubule",
      //     },
      //     { value: "Effector_T", label: "Effector_T" },
      //     { value: "Endothelial_1", label: "Endothelial_1" },
      //     { value: "Endothelial_2", label: "Endothelial_2" },
      //     { value: "Endothelial_3", label: "Endothelial_3" },
      //     { value: "Fibroblast", label: "Fibroblast" },
      //     { value: "Macrophage", label: "Macrophage" },
      //     { value: "Mesangial_cell", label: "Mesangial_cell" },
      //     { value: "MoDC", label: "MoDC" },
      //     { value: "Naive_T", label: "Naive_T" },
      //     { value: "Podocyte", label: "Podocyte" },
      //     {
      //       value: "Proximal_convoluted_tubule_1",
      //       label: "Proximal_convoluted_tubule_1",
      //     },
      //     {
      //       value: "Proximal_convoluted_tubule_2",
      //       label: "Proximal_convoluted_tubule_2",
      //     },
      //     {
      //       value: "Proximal_straight_tubule",
      //       label: "Proximal_straight_tubule",
      //     },
      //     { value: "Smooth_muscle", label: "Smooth_muscle" },
      //     { value: "Thick_ascending_limb", label: "Thick_ascending_limb" },
      //   ],
      //   lacrimal: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Acinar_1", label: "Acinar_1" },
      //     { value: "Ductal", label: "Ductal" },
      //     { value: "Endothelial", label: "Endothelial" },
      //     { value: "Epithelial", label: "Epithelial" },
      //     { value: "Fibroblast", label: "Fibroblast" },
      //     { value: "Macrophage_Monocyte", label: "Macrophage_Monocyte" },
      //     { value: "Mast_cell_1", label: "Mast_cell_1" },
      //     { value: "Mast_cell_2", label: "Mast_cell_2" },
      //     { value: "Mast_cell_3", label: "Mast_cell_3" },
      //     { value: "Myoepithelial_1", label: "Myoepithelial_1" },
      //     { value: "Myoepithelial_2", label: "Myoepithelial_2" },
      //     { value: "T_NK", label: "T_NK" },
      //     { value: "T_cell", label: "T_cell" },
      //   ],
      //   liver: [
      //     { value: "all", label: "All cell types" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Cholangiocyte", label: "Cholangiocyte" },
      //     { value: "Endothelial_1", label: "Endothelial_1" },
      //     { value: "Endothelial_2", label: "Endothelial_2" },
      //     { value: "Hepatocyte_1", label: "Hepatocyte_1" },
      //     { value: "Hepatocyte_2", label: "Hepatocyte_2" },
      //     { value: "Hepatocyte_3", label: "Hepatocyte_3" },
      //     { value: "Macrophage", label: "Macrophage" },
      //     { value: "Mesenchyme", label: "Mesenchyme" },
      //     { value: "T_cell", label: "T_cell" },
      //   ],
      //   lung: [
      //     { value: "all", label: "All cell types" },
      //     { value: "AT1", label: "AT1" },
      //     { value: "AT2", label: "AT2" },
      //     { value: "Active_CD4_T", label: "Active_CD4_T" },
      //     { value: "Active_CD8_T", label: "Active_CD8_T" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Basal", label: "Basal" },
      //     { value: "Ciliated", label: "Ciliated" },
      //     { value: "Club", label: "Club" },
      //     { value: "Endothelial_1", label: "Endothelial_1" },
      //     { value: "Endothelial_2", label: "Endothelial_2" },
      //     { value: "Fibroblast", label: "Fibroblast" },
      //     { value: "Macrophage_1", label: "Macrophage_1" },
      //     { value: "Macrophage_2", label: "Macrophage_2" },
      //     { value: "Macrophage_3", label: "Macrophage_3" },
      //     { value: "Macrophage_4", label: "Macrophage_4" },
      //     { value: "Mast_cell", label: "Mast_cell" },
      //     { value: "NK_cell", label: "NK_cell" },
      //     { value: "Naive_CD4_T", label: "Naive_CD4_T" },
      //     { value: "Naive_CD8_T", label: "Naive_CD8_T" },
      //     { value: "Neutrophil", label: "Neutrophil" },
      //     { value: "Pericyte", label: "Pericyte" },
      //     { value: "Smooth_muscle", label: "Smooth_muscle" },
      //   ],
      //   pancreas: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Acinar_cell_1", label: "Acinar_cell_1" },
      //     { value: "Acinar_cell_2", label: "Acinar_cell_2" },
      //     { value: "Acinar_cell_3", label: "Acinar_cell_3" },
      //     { value: "B_cell", label: "B_cell" },
      //     { value: "Ductal_cell_1", label: "Ductal_cell_1" },
      //     { value: "Ductal_cell_2", label: "Ductal_cell_2" },
      //     { value: "Ductal_cell_3", label: "Ductal_cell_3" },
      //     { value: "Endocrine_α", label: "Endocrine_α" },
      //     { value: "Endocrine_β_1", label: "Endocrine_β_1" },
      //     { value: "Endocrine_β_2", label: "Endocrine_β_2" },
      //     { value: "Endocrine_β_3", label: "Endocrine_β_3" },
      //     { value: "Endocrine_β_4", label: "Endocrine_β_4" },
      //     { value: "Endocrine_δ", label: "Endocrine_δ" },
      //     { value: "Endothelial", label: "Endothelial" },
      //     { value: "Fibroblast_1", label: "Fibroblast_1" },
      //     { value: "Fibroblast_2", label: "Fibroblast_2" },
      //     { value: "Myeloid", label: "Myeloid" },
      //     { value: "PP_cell", label: "PP_cell" },
      //     { value: "Smooth_muscle", label: "Smooth_muscle" },
      //     { value: "T_NK", label: "T_NK" },
      //   ],
      //   salivary: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Basal", label: "Basal" },
      //     { value: "Endothelial_1", label: "Endothelial_1" },
      //     { value: "Endothelial_2", label: "Endothelial_2" },
      //     { value: "Fibroblast", label: "Fibroblast" },
      //     { value: "ILC2", label: "ILC2" },
      //     { value: "Macrophage", label: "Macrophage" },
      //     { value: "Neuron", label: "Neuron" },
      //     { value: "Neutrophil", label: "Neutrophil" },
      //     { value: "SLG_Acinar", label: "SLG_Acinar" },
      //     { value: "SLG_Ductal", label: "SLG_Ductal" },
      //     { value: "SMG_Acinar", label: "SMG_Acinar" },
      //     { value: "SMG_Ductal_1", label: "SMG_Ductal_1" },
      //     { value: "SMG_Ductal_2", label: "SMG_Ductal_2" },
      //     { value: "SMG_Ductal_3", label: "SMG_Ductal_3" },
      //     { value: "SMG_Ductal_4", label: "SMG_Ductal_4" },
      //     { value: "SMG_Ductal_5", label: "SMG_Ductal_5" },
      //     { value: "SMG_Ductal_6", label: "SMG_Ductal_6" },
      //     { value: "Smooth_muscle_1", label: "Smooth_muscle_1" },
      //     { value: "Smooth_muscle_2", label: "Smooth_muscle_2" },
      //     { value: "T_cell", label: "T_cell" },
      //   ],
      //   skeletal_muscle: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Adipose_SC", label: "Adipose_SC" },
      //     { value: "Endothelial_1", label: "Endothelial_1" },
      //     { value: "Endothelial_2", label: "Endothelial_2" },
      //     { value: "Endothelial_3", label: "Endothelial_3" },
      //     { value: "Endothelial_4", label: "Endothelial_4" },
      //     { value: "FAP", label: "FAP" },
      //     { value: "Gial", label: "Gial" },
      //     { value: "Macrophage_1", label: "Macrophage_1" },
      //     { value: "Macrophage_2", label: "Macrophage_2" },
      //     { value: "Monocyte_1", label: "Monocyte_1" },
      //     { value: "Monocyte_2", label: "Monocyte_2" },
      //     { value: "MuSC", label: "MuSC" },
      //     { value: "Neutrophil_1", label: "Neutrophil_1" },
      //     { value: "Neutrophil_2", label: "Neutrophil_2" },
      //     { value: "Neutrophil_3", label: "Neutrophil_3" },
      //     { value: "Pericyte", label: "Pericyte" },
      //     { value: "Plasma", label: "Plasma" },
      //     { value: "Plasmablast_1", label: "Plasmablast_1" },
      //     { value: "Plasmablast_2", label: "Plasmablast_2" },
      //     { value: "Preadipocyte", label: "Preadipocyte" },
      //     { value: "SM", label: "SM" },
      //     { value: "Schwann", label: "Schwann" },
      //     { value: "Smooth_muscle", label: "Smooth_muscle" },
      //     { value: "T_cell", label: "T_cell" },
      //     { value: "Tenocyte", label: "Tenocyte" },
      //   ],
      //   spleen: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Active_CD4_T", label: "Active_CD4_T" },
      //     { value: "Active_CD8_T", label: "Active_CD8_T" },
      //     { value: "B_cell_1", label: "B_cell_1" },
      //     { value: "B_cell_2", label: "B_cell_2" },
      //     { value: "B_cell_3", label: "B_cell_3" },
      //     { value: "B_cell_4", label: "B_cell_4" },
      //     { value: "Dendritic_cell", label: "Dendritic_cell" },
      //     { value: "Endothelial", label: "Endothelial" },
      //     { value: "Macrophage_1", label: "Macrophage_1" },
      //     { value: "Macrophage_2", label: "Macrophage_2" },
      //     { value: "Macrophage_3", label: "Macrophage_3" },
      //     { value: "Macrophage_4", label: "Macrophage_4" },
      //     { value: "NK_cell", label: "NK_cell" },
      //     { value: "Naive_CD4_T", label: "Naive_CD4_T" },
      //     { value: "Naive_CD8_T", label: "Naive_CD8_T" },
      //     { value: "Neutrophil", label: "Neutrophil" },
      //     { value: "Plasma", label: "Plasma" },
      //     { value: "Th17", label: "Th17" },
      //   ],
      //   stomach: [
      //     { value: "all", label: "All cell types" },
      //     { value: "Chief_cell_1", label: "Chief_cell_1" },
      //     { value: "Chief_cell_2", label: "Chief_cell_2" },
      //     { value: "ECL_cell", label: "ECL_cell" },
      //     { value: "Endothelial", label: "Endothelial" },
      //     { value: "Epithelial", label: "Epithelial" },
      //     { value: "Fibroblast", label: "Fibroblast" },
      //     { value: "Foveolar_cell_1", label: "Foveolar_cell_1" },
      //     { value: "Foveolar_cell_2", label: "Foveolar_cell_2" },
      //     { value: "Foveolar_cell_3", label: "Foveolar_cell_3" },
      //     { value: "Foveolar_cell_4", label: "Foveolar_cell_4" },
      //     { value: "Foveolar_cell_5", label: "Foveolar_cell_5" },
      //     { value: "G_D_cell", label: "G_D_cell" },
      //     { value: "Immune_cell", label: "Immune_cell" },
      //     { value: "Mucous_cell_1", label: "Mucous_cell_1" },
      //     { value: "Mucous_cell_2", label: "Mucous_cell_2" },
      //     { value: "Parietal_cell", label: "Parietal_cell" },
      //     { value: "Tuft_cell", label: "Tuft_cell" },
      //   ],
      //   thymus: [
      //     { value: "all", label: "All cell types" },
      //     { value: "B_cell_1", label: "B_cell_1" },
      //     { value: "DN", label: "DN" },
      //     { value: "DPblast_1", label: "DPblast_1" },
      //     { value: "DPblast_2", label: "DPblast_2" },
      //     { value: "DPres", label: "DPres" },
      //     { value: "Macrophage", label: "Macrophage" },
      //     { value: "Mito_high", label: "Mito_high" },
      //     { value: "SP", label: "SP" },
      //   ],
      // },
      cellTypeValue: "all",
      changeValue: "all",
      changeOptions: [
        {
          value: "all",
          label: "All",
        },
        {
          value: "Up",  // up
          label: "Up",
        },
        {
          value: "Down",  // down
          label: "Down",
        },
      ],

      tableTitleColumns: [
        {
          title: "Gene_symbol_unique",
          key: "Gene_symbol_unique",
          fixed: "left",
          width: 180,
        },
        {
          title: "p_val",
          key: "p_val",
          width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "log2FC",  // avg_log2FC
          key: "log2FC",  // avg_log2FC
          width: 150,
          sortable: true,
          resizable: true,
        },
        {
          title: "pct.1",
          key: "pct.1",
          width: 100,
          sortable: true,
          resizable: true,
        },
        {
          title: "pct.2",
          key: "pct.2",
          width: 100,
          sortable: true,
          resizable: true,
        },
        {
          title: "p_val_adj",
          key: "p_val_adj",
          width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "Gene_symbol",
          key: "Gene_symbol",
          width: 160,
          sortable: true,
          resizable: true,
        },
        {
          title: "Cell_type",
          key: "Cell_type",
          width: 170,
          sortable: true,
          resizable: true,
        },
        {
          title: "Change",
          key: "Change",
          width: 120,
          sortable: true,
          resizable: true,
        },
        {
          title: "Tissue",
          key: "Tissue",
          width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "Comparison",
          key: "Comparison",
          width: 150,
          sortable: true,
          resizable: true,
        },
      ],

      tableLoading: false,
      tableDataset: [],
      tableDataList: [],
      tableData: [],
      pageNum: 1,
      pageSize: 10,
    };
  },
  created: function () {
    this.getTableDataset();
  },
  methods: {
    getTableDataset() {
      this.tableLoading = true;
      let that = this;
      this.$axios
        .get("json/", {
          // that.url + "json/", {
          params: {
            jsonID: "deg",
          },
        })
        .then(function (response) {
          // console.log(response.data.result);
          that.tableDataset = response.data.result;
          that.tableDataList = response.data.result;
          that.filterTable(
            that.tissueValue,
            that.comparisonValue,
            that.cellTypeValue,
            that.changeValue
          );
          that.tableLoading = false;
        })
        .catch(function (err) {
          alert(err);
        });
    },
    getTable() {
      // this.tableLoading = true;
      var that = this;
      that.tableLoading = true;
      setTimeout(() => {
        that.tableData = that.tableDataList.slice(0, that.pageSize);
        that.tableLoading = false;
      }, 1000);
    },
    changePage(index) {
      // this.tableData = this.tableDataList.slice((index - 1) * 10, index * 10);
      this.tableData = this.tableDataList.slice(
        (index - 1) * this.pageSize,
        index * this.pageSize
      );
      this.pageNum = index;
    },
    handlePageSize(index) {
      // console.log(index);
      this.pageSize = index;
      this.getTable();
    },
    changeTissue(t) {
      this.selectTissue = t;
      this.comparisonValue = this.$options.data().comparisonValue;
      this.cellTypeValue = this.$options.data().cellTypeValue;
      this.changeValue = this.$options.data().changeValue;
      this.filterTable(t, "all", "all", "all");
    },
    filterTable(a, b, c, d) {
      // console.log(a, b, c, d);
      this.tableLoading = true;
      let selectTissue = a;
      let selectComparison = b;
      let selectCellType = c;
      let selectChange = d;
      // let primaryData = getTableDataset().tableDataList;
      let primaryData = this.tableDataset;
      let len = primaryData.length;
      let list = [];
      for (let item = 0; item < len; item++) {
        if (selectTissue != primaryData[item].Tissue) {
          continue;
        }
        if (selectComparison != "all") {
          if (selectComparison != primaryData[item].Comparison) {
            continue;
          }
        }
        if (selectCellType != "all") {
          if (selectCellType != primaryData[item].Cell_type) {
            continue;
          }
        }
        if (selectChange != "all") {
          if (selectChange != primaryData[item].Change) {
            continue;
          }
        }
        list.push(primaryData[item]);
      }
      // console.log(list);
      this.tableDataList = list;
      this.tableLoading = false;
      this.changePage(1);
    },
  },
  computed: {
    total: function () {
      return this.tableDataList.length;
    },
    cellTypeOptions: function () {
      return this.cellTypeOptionsDict[this.tissueValue];
    },
  },
};
</script>

<style scoped>
/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-card {
  width: 1320px;
  margin: 20px 0 100px 0;
}

.box-card-item {
  font-size: 20px;
  font-weight: bold;
}

.box-card-item-icon {
  color: #223468;
}

/* select */
.select-container {
  margin: 15px 0 20px 0;
  width: 100%;
  /* height: 100px; */
  background-color: #f4f6fc;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
}

.select-components {
  width: 100%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
}

.select-title {
  font-size: 16px;
  margin: 0 0 10px 0;
}

.table-page {
  margin: 20px 0;
  text-align: center;
  /* border: 1px solid red; */
}

/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }

  .select-container {
    flex-direction: column;
  }

  .select-components {
    margin: 2% 0;
    padding: 0;
    /* border: 1px solid red; */
  }
}</style>