<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-record">Cell Atlas involving Sex and Androgens</div>
      <div class="footer-company">Copyright © 2022-2024. All rights reserved. Powered by <a
          href="https://zaakzoeng.github.io/" target="_blank" style="color: aqua;
      ">Ze Zhang</a>.</div>
      <div class="footer-link">Li, F., Xing, X., Jin, Q., Wang, X. M., Dai, P., Han, M., Shi, H., Zhang, Z., Shao, X.,
        Peng, Y., Zhu, Y., Xu, J., Li, D., Chen, Y., Wu, W., Wang, Q., Yu, C., Chen, L., Bai, F., & Gao, D. (2024). Sex
        differences orchestrated by androgens at single-cell resolution. Nature, 10.1038/s41586-024-07291-6. Advance
        online publication. <a href="https://doi.org/10.1038/s41586-024-07291-6" target="_blank" style="color: aqua;
      ">https://doi.org/10.1038/s41586-024-07291-6</a>.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282828;
}

.footer-content {
  width: 1280px;
  text-align: center;
  color: #fff;
  /* padding-top: 80px; */
}

.footer-record {
  font-size: 16px;
}

.footer-company {
  font-size: 14px;
  margin-bottom: 30px;
}

/* .footer-link {
  width: 80%;
} */</style>