<template>
  <div>
    <div class="block-container">
      <el-card class="block-card">
        <div class="box-card-gene-exp">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-dna" />
            </span>
            <span> Gene expression</span>
          </div>
        </div>
        <!-- Select -->
        <div class="select-container">
          <div class="select-components">
            <div class="select-title">Tissue</div>
            <div class="select-content">
              <el-select
                v-model="tissueValue"
                style="width: 100%"
                filterable
                @change="changeTissue(tissueValue)"
              >
                <el-option
                  v-for="item in tissueOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Condition</div>
            <div class="select-content">
              <el-select
                v-model="conditionValue"
                style="width: 100%"
                @change="
                  changeCondition(tissueValue, conditionValue, geneValue)
                "
              >
                <el-option
                  v-for="item in conditionOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Gene</div>
            <div class="select-content">
              <el-select
                v-model="geneValue"
                style="width: 100%"
                filterable
                placeholder="Search a gene"
                :loading="geneLoading"
                loading-text="Loading..."
                @visible-change="getGeneOptions($event, tissueValue)"
                @change="
                  getRightPlotlyResult(tissueValue, conditionValue, geneValue)
                "
              >
                <el-option
                  v-for="item in geneOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="box-card-plotly">
          <el-row>
            <el-col :span="13" v-loading="leftLoading">
              <div class="plotly-left">
                <iframe
                  :src="getLeftPlotly"
                  scrolling="no"
                  style="border: none"
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </el-col>
            <el-col :span="11" v-loading="rightLoading">
              <div class="plotly-right">
                <iframe
                  :src="getRightPlotly"
                  scrolling="no"
                  style="border: none"
                  width="100%"
                  height="100%"
                >
                </iframe>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- mobile-box-card-plotly -->
        <div class="mobile-box-card-plotly">
          <!-- <el-row> -->
          <!-- <el-col :span="13" v-loading="leftLoading"> -->
          <div class="plotly-left" v-loading="leftLoading">
            <iframe
              :src="getLeftPlotly"
              scrolling="no"
              style="border: none"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <!-- </el-col> -->
          <!-- <el-col :span="11" v-loading="rightLoading"> -->
          <div class="plotly-right" v-loading="rightLoading">
            <iframe
              :src="getRightPlotly"
              scrolling="no"
              style="border: none"
              width="100%"
              height="100%"
            >
            </iframe>
          </div>
          <!-- </el-col> -->
          <!-- </el-row> -->
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneExpression",
  data() {
    return {
      // url: "http://127.0.0.1:8000/",

      tissueValue: "adipose",
      tissueOptions: [
        {
          value: "adipose",
          label: "Adipose",
        },
        {
          value: "adrenal",
          label: "Adrenal",
        },
        {
          value: "bone_marrow",
          label: "Bone marrow",
        },
        {
          value: "brain",
          label: "Brain",
        },
        {
          value: "colon",
          label: "Colon",
        },
        {
          value: "heart",
          label: "Heart",
        },
        {
          value: "intestine",
          label: "Intestine",
        },
        {
          value: "kidney",
          label: "Kidney",
        },
        {
          value: "lacrimal",
          label: "Lacrimal",
        },
        {
          value: "liver",
          label: "Liver",
        },
        {
          value: "lung",
          label: "Lung",
        },
        {
          value: "pancreas",
          label: "Pancreas",
        },
        {
          value: "salivary",
          label: "Salivary",
        },
        {
          value: "skeletalmuscle",
          label: "Skeletal muscle",
        },
        {
          value: "spleen",
          label: "Spleen",
        },
        {
          value: "stomach",
          label: "Stomach",
        },
        {
          value: "thymus",
          label: "Thymus",
        },
      ],
      conditionValue: "all",
      conditionOptions: [
        {
          value: "all",
          label: "All conditions",
        },
        {
          value: "FD",
          label: "FD",
        },
        {
          value: "FS",
          label: "FS",
        },
        {
          value: "MC",
          label: "MC",
        },
        {
          value: "MS",
          label: "MS",
        },
      ],

      geneValue: "",
      geneOptions: [],

      leftPlotlyFileDict: {
        adipose: {
          all: "adiposeColoredbyCellType",
          FD: "adiposeColoredbyCellTypeFD",
          FS: "adiposeColoredbyCellTypeFS",
          MC: "adiposeColoredbyCellTypeMC",
          MS: "adiposeColoredbyCellTypeMS",
        },
        adrenal: {
          all: "adrenalColoredbyCellType",
          FD: "adrenalColoredbyCellTypeFD",
          FS: "adrenalColoredbyCellTypeFS",
          MC: "adrenalColoredbyCellTypeMC",
          MS: "adrenalColoredbyCellTypeMS",
        },
        bone_marrow: {
          all: "boneMarrowColoredbyCellType",
          FD: "boneMarrowColoredbyCellTypeFD",
          FS: "boneMarrowColoredbyCellTypeFS",
          MC: "boneMarrowColoredbyCellTypeMC",
          MS: "boneMarrowColoredbyCellTypeMS",
        },
        brain: {
          all: "brainColoredbyCellType",
          FD: "brainColoredbyCellTypeFD",
          FS: "brainColoredbyCellTypeFS",
          MC: "brainColoredbyCellTypeMC",
          MS: "brainColoredbyCellTypeMS",
        },
        colon: {
          all: "colonColoredbyCellType",
          FD: "colonColoredbyCellTypeFD",
          FS: "colonColoredbyCellTypeFS",
          MC: "colonColoredbyCellTypeMC",
          MS: "colonColoredbyCellTypeMS",
        },
        heart: {
          all: "heartColoredbyCellType",
          FD: "heartColoredbyCellTypeFD",
          FS: "heartColoredbyCellTypeFS",
          MC: "heartColoredbyCellTypeMC",
          MS: "heartColoredbyCellTypeMS",
        },
        intestine: {
          all: "intestineColoredbyCellType",
          FD: "intestineColoredbyCellTypeFD",
          FS: "intestineColoredbyCellTypeFS",
          MC: "intestineColoredbyCellTypeMC",
          MS: "intestineColoredbyCellTypeMS",
        },
        kidney: {
          all: "kidneyColoredbyCellType",
          FD: "kidneyColoredbyCellTypeFD",
          FS: "kidneyColoredbyCellTypeFS",
          MC: "kidneyColoredbyCellTypeMC",
          MS: "kidneyColoredbyCellTypeMS",
        },
        lacrimal: {
          all: "lacrimalColoredbyCellType",
          FD: "lacrimalColoredbyCellTypeFD",
          FS: "lacrimalColoredbyCellTypeFS",
          MC: "lacrimalColoredbyCellTypeMC",
          MS: "lacrimalColoredbyCellTypeMS",
        },
        liver: {
          all: "liverColoredbyCellType",
          FD: "liverColoredbyCellTypeFD",
          FS: "liverColoredbyCellTypeFS",
          MC: "liverColoredbyCellTypeMC",
          MS: "liverColoredbyCellTypeMS",
        },
        lung: {
          all: "lungColoredbyCellType",
          FD: "lungColoredbyCellTypeFD",
          FS: "lungColoredbyCellTypeFS",
          MC: "lungColoredbyCellTypeMC",
          MS: "lungColoredbyCellTypeMS",
        },
        pancreas: {
          all: "pancreasColoredbyCellType",
          FD: "pancreasColoredbyCellTypeFD",
          FS: "pancreasColoredbyCellTypeFS",
          MC: "pancreasColoredbyCellTypeMC",
          MS: "pancreasColoredbyCellTypeMS",
        },
        salivary: {
          all: "salivaryColoredbyCellType",
          FD: "salivaryColoredbyCellTypeFD",
          FS: "salivaryColoredbyCellTypeFS",
          MC: "salivaryColoredbyCellTypeMC",
          MS: "salivaryColoredbyCellTypeMS",
        },
        skeletalmuscle: {
          all: "skeletalmuscleColoredbyCellType",
          FD: "skeletalmuscleColoredbyCellTypeFD",
          FS: "skeletalmuscleColoredbyCellTypeFS",
          MC: "skeletalmuscleColoredbyCellTypeMC",
          MS: "skeletalmuscleColoredbyCellTypeMS",
        },
        spleen: {
          all: "spleenColoredbyCellType",
          FD: "spleenColoredbyCellTypeFD",
          FS: "spleenColoredbyCellTypeFS",
          MC: "spleenColoredbyCellTypeMC",
          MS: "spleenColoredbyCellTypeMS",
        },
        stomach: {
          all: "stomachColoredbyCellType",
          FD: "stomachColoredbyCellTypeFD",
          FS: "stomachColoredbyCellTypeFS",
          MC: "stomachColoredbyCellTypeMC",
          MS: "stomachColoredbyCellTypeMS",
        },
        thymus: {
          all: "thymusColoredbyCellType",
          FD: "thymusColoredbyCellTypeFD",
          FS: "thymusColoredbyCellTypeFS",
          MC: "thymusColoredbyCellTypeMC",
          MS: "thymusColoredbyCellTypeMS",
        },
      },

      leftLoading: false,
      rightLoading: false,
      geneLoading: true,
      getLeftPlotly: "",
      getRightPlotly: "",
    };
  },
  methods: {
    changeTissue(t) {
      // console.log(t);
      this.conditionValue = this.$options.data().conditionValue;
      this.geneValue = this.$options.data().geneValue;
      this.rightLoading = this.$options.data().rightLoading;
      this.getRightPlotly = this.$options.data().getRightPlotly;
      // this.getGeneOptions(t);
      this.getLeftPlotlyResult(this.leftPlotlyFileDict[t]["all"]);
    },
    changeCondition(a, b, c) {
      // console.log(a, b, c);
      this.getLeftPlotlyResult(this.leftPlotlyFileDict[a][b]);
      if (c != "") {
        this.getRightPlotlyResult(a, b, c);
      }
    },
    getGeneOptions(callback, a) {
      // console.log("回调参数", callback);
      if (callback) {
        this.geneLoading = true;
        // console.log(a);
        let json_name = "gene_exp_" + a;
        // console.log(json_name);
        let that = this;
        this.$axios
          .get("json/", {
            // that.url + "json/", {
            params: {
              jsonID: json_name,
            },
          })
          .then(function (response) {
            // console.log(response.data.result);
            that.geneOptions = response.data.result;
            setTimeout(() => {
              that.geneLoading = false;
            }, 2000);
          })
          .catch(function (err) {
            alert(err);
          });
      }
    },
    getLeftPlotlyResult(file_name) {
      let that = this;
      this.leftLoading = true;
      this.$axios
        .get("html/", {
          // that.url + "html/", {
          params: {
            htmlID: file_name,
          },
        })
        .then(function (response) {
          // console.log(response);
          that.getLeftPlotly =
            that.axios.defaults.baseURL + "html/" + "?htmlID=" + file_name;
          setTimeout(() => {
            that.leftLoading = false;
          }, 1000);
        })
        .catch(function (err) {
          alert(err);
        });
    },
    getRightPlotlyResult(a, b, c) {
      let that = this;
      this.rightLoading = true;
      this.$axios
        .get("plotly/", {
          // that.url + "plotly/", {
          params: {
            tissue: a,
            condition: b,
            gene: c,
          },
        })
        .then(function (response) {
          // console.log(response);
          that.getRightPlotly =
            // that.url +
            that.axios.defaults.baseURL +
            "plotly/" +
            "?tissue=" +
            a +
            "&condition=" +
            b +
            "&gene=" +
            c;
          setTimeout(() => {
            that.rightLoading = false;
          }, 4000);
        })
        .catch(function (err) {
          alert(err);
        });
    },
  },
  mounted: function () {
    // console.log(this.leftPlotlyFileDict[this.tissueValue][this.conditionValue]);
    this.getLeftPlotlyResult(
      this.leftPlotlyFileDict[this.tissueValue][this.conditionValue]
    );
  },
};
</script>

<style scoped>
/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block-card {
  width: 1320px;
  margin: 20px 0 100px 0;
}
.box-card-item {
  font-size: 20px;
  font-weight: bold;
}
.box-card-item-icon {
  color: #223468;
}
/* select */
.select-container {
  margin: 15px 0 20px 0;
  width: 100%;
  /* height: 100px; */
  background-color: #f4f6fc;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
}
.select-components {
  width: 100%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
}
.select-title {
  font-size: 16px;
  margin: 0 0 10px 0;
}
.plotly-left {
  height: 640px;
  width: 100%;
}
.plotly-right {
  height: 640px;
  width: 100%;
}
/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }
  .select-container {
    flex-direction: column;
  }
  .select-components {
    margin: 2% 0;
    padding: 0;
    /* border: 1px solid red; */
  }
}
@media screen and (max-width: 1000px) {
  .box-card-plotly {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .mobile-box-card-plotly {
    display: none;
  }
}
</style>