<template>
  <div>
    <div class="block-container">
      <el-card class="block-card">
        <div class="box-card-gene-exp">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-download" />
            </span>
            <span> Download</span>
          </div>
        </div>
        <!-- Download 2 -->
        <div id="download-info2" class="box-card-download" v-for="(list, index) in downloadList2" :key="index">
          <div class="download-item">
            <span class="download-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <!-- <span> Metadata (17 files)</span> -->
            <span>{{ list.labelTitle }}</span>
          </div>
          <div class="download-item-header">
            <div class="download-item-content">
              <span>
                {{ list.contentMsg }}
              </span>
            </div>
            <div class="download-all-button">
              <Button icon="ios-download-outline" type="default" @click="downloadFile(list.downloadAll)">Download
                All</Button>
            </div>
          </div>
          <div class="download-item-table">
            <Table :height="list.downloadSubList.length < 5 ? '' : '290'" border stripe :columns="tableCcolumns"
              :data="list.downloadSubList"></Table>
          </div>
        </div>
        <!-- form -->
        <div v-if="!formSubmitted" class="box-card-download">
          <div class="download-item">
            <span class="download-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> Expression data and metadata</span>
          </div>
          <div style="margin: 15px 0; font-size: 16px; text-align: justify; line-height: 1.6">
            <span>
              If you need Expression matrix and Metadata information, please submit for download. Expression matrix includes gene expressions
              across all the cells of that tissue. and Metadata information includes cell_id, cell_type, condition, tissue and UMAP values.
            </span>
          </div>
          <div>
            <el-card shadow="never">
              <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-position="top"
                label-width="100px" class="demo-ruleForm">
                <!-- 姓名 -->
                <el-form-item label="Name" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <!-- 邮箱 -->
                <el-form-item label="Mail" prop="email">
                  <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <!-- 组织单位 -->
                <el-form-item label="Institute" prop="institute">
                  <el-input v-model="ruleForm.institute"></el-input>
                </el-form-item>
                <!-- PI 的姓名 -->
                <el-form-item label="Name of PI" prop="pi">
                  <el-input v-model="ruleForm.pi"></el-input>
                </el-form-item>
                <!-- 摘要 -->
                <el-form-item label="Brief abstract of your Project in which the Data will be used" prop="abstract">
                  <el-input type="textarea" v-model.number="ruleForm.abstract"></el-input>
                </el-form-item>
                <!-- 描述 -->
                <el-form-item label="Description on the methods and technologies of the project" prop="description">
                  <el-input type="textarea" v-model.number="ruleForm.description"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">Submit for download</el-button>
                  <el-button @click="resetForm('ruleForm')">Reset</el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </div>
        </div>
        <!-- download 1 -->
        <div v-show="formSubmitted" id="download-info" class="box-card-download" v-for="(list, index) in downloadList"
          :key="index">
          <div class="download-item">
            <span class="download-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <!-- <span> Metadata (17 files)</span> -->
            <span>{{ list.labelTitle }}</span>
          </div>
          <div class="download-item-header">
            <div class="download-item-content">
              <span>
                {{ list.contentMsg }}
              </span>
            </div>
            <div class="download-all-button">
              <Button icon="ios-download-outline" type="default" @click="downloadFile(list.downloadAll)">Download
                All</Button>
            </div>
          </div>
          <div class="download-item-table">
            <Table :height="list.downloadSubList.length < 5 ? '' : '290'" border stripe :columns="tableCcolumns"
              :data="list.downloadSubList"></Table>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {

    return {
      // url: "http://127.0.0.1:8000/",

      formSubmitted: false, // 默认不展示

      // 表单数据
      ruleForm: {
        name: '',
        email: '',
        institute: '',
        pi: '',
        abstract: '',
        description: ''
      },
      rules: {
        name: [
          { required: true, message: 'Please enter your name', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please enter your email address', trigger: 'blur' },
          { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
        ],
        institute: [
          { required: true, message: 'Please enter your institute', trigger: 'blur' }
        ],
        pi: [
          { required: true, message: 'Please enter the name of PI', trigger: 'blur' }
        ],
        abstract: [
          { required: true, message: 'Please enter the abstract', trigger: 'blur' }
        ],
        description: [
          { required: true, message: 'Please enter the description', trigger: 'blur' }
        ]
      },

      tableCcolumns: [
        {
          title: "File name",
          key: "name",
          fixed: "left",
          width: 300,
          render: (h, params) => {
            return h("div", [
              h("Icon", {
                props: {
                  type: "person",
                },
              }),
              h("strong", params.row.name),
            ]);
          },
        },
        {
          title: "Size",
          key: "size",
          minWidth: 300,
          align: "center",
        },
        {
          title: "Action",
          key: "action",
          minWidth: 300,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "25px",
                  },
                  on: {
                    click: () => {
                      this.show(
                        params.row.name,
                        params.row.size,
                        params.row.desc
                      );
                    },
                  },
                },
                "View"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "default",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.downloadFile(params.row.filePath);
                    },
                  },
                },
                "Download"
              ),
            ]);
          },
        },
      ],
      downloadList: [
        {
          labelTitle: " Metadata (17 files)",
          contentMsg:
            "Metadata information includes cell_id, cell_type, condition, tissue and UMAP values.",
          downloadAll: {
            path: "metadata",
            file: "CASA.meta.data.zip",
          },
          downloadSubList: [
            {
              name: "CASA.meta.data.adipose.csv",
              size: "8.16 MB",  // 6.28
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.adipose.csv",
              },
              desc: "Metadata information for adipose",
            },
            {
              name: "CASA.meta.data.adrenal.csv",
              size: "6.16 MB",  // 3.72
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.adrenal.csv",
              },
              desc: "Metadata information for adrenal",
            },
            {
              name: "CASA.meta.data.bone_marrow.csv",
              size: "7.73 MB",  // 4.75
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.bone_marrow.csv",
              },
              desc: "Metadata information for bone marrow",
            },
            {
              name: "CASA.meta.data.brain.csv",
              size: "9.00 MB",  // 6.43
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.brain.csv",
              },
              desc: "Metadata information for brain",
            },
            {
              name: "CASA.meta.data.colon.csv",
              size: "4.14 MB",  // 3.24
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.colon.csv",
              },
              desc: "Metadata information for colon",
            },
            {
              name: "CASA.meta.data.heart.csv",
              size: "8.53 MB",  // 9.41
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.heart.csv",
              },
              desc: "Metadata information for heart",
            },
            {
              name: "CASA.meta.data.intestine.csv",
              size: "6.33 MB",  // 4.52
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.intestine.csv",
              },
              desc: "Metadata information for intestine",
            },
            {
              name: "CASA.meta.data.kidney.csv",
              size: "6.30 MB",  // 4.82
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.kidney.csv",
              },
              desc: "Metadata information for kidney",
            },
            {
              name: "CASA.meta.data.lacrimal.csv",
              size: "8.78 MB",  // 4.15
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.lacrimal.csv",
              },
              desc: "Metadata information for lacrimal",
            },
            {
              name: "CASA.meta.data.liver.csv",
              size: "10.50 MB",  // 14.2
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.liver.csv",
              },
              desc: "Metadata information for liver",
            },
            {
              name: "CASA.meta.data.lung.csv",
              size: "15.40 MB",  // 8.47
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.lung.csv",
              },
              desc: "Metadata information for lung",
            },
            {
              name: "CASA.meta.data.pancreas.csv",
              size: "7.73 MB",  // 3.57
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.pancreas.csv",
              },
              desc: "Metadata information for pancreas",
            },
            {
              name: "CASA.meta.data.salivary.csv",
              size: "7.93 MB",  // 5.72
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.salivary.csv",
              },
              desc: "Metadata information for salivary",
            },
            {
              name: "CASA.meta.data.skeletalmuscle.csv",
              size: "11.60 MB",  // 9.38
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.skeletalmuscle.csv",
              },
              desc: "Metadata information for skeletal muscle",
            },
            {
              name: "CASA.meta.data.spleen.csv",
              size: "8.89 MB",  // 6.47
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.spleen.csv",
              },
              desc: "Metadata information for spleen",
            },
            {
              name: "CASA.meta.data.stomach.csv",
              size: "7.95 MB",  // 3.22
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.stomach.csv",
              },
              desc: "Metadata information for stomach",
            },
            {
              name: "CASA.meta.data.thymus.csv",
              size: "7.95 MB",  // 3.82
              filePath: {
                path: "metadata",
                file: "CASA.meta.data.thymus.csv",
              },
              desc: "Metadata information for thymus",
            },
          ],
        },
        {
          labelTitle: " Expression matrix (17 files)",
          contentMsg:
            "Expression matrix for gene expressions across all the cells of that tissue.",
          downloadAll: {
            path: "expressionMatrix",
            file: "exp.data.zip",
          },
          downloadSubList: [
            {
              name: "exp.data.adipose.rds.zip",
              size: "379 MB", // 289
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.adipose.zip",
              },
              desc: "Expression matrix for adipose",
            },
            {
              name: "exp.data.adrenal.rds.zip",
              size: "389 MB", // 227
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.adrenal.zip",
              },
              desc: "Expression matrix for adrenal",
            },
            {
              name: "exp.data.bone_marrow.rds.zip",
              size: "279 MB", // 176
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.bone_marrow.zip",
              },
              desc: "Expression matrix for bone marrow",
            },
            {
              name: "exp.data.brain.rds.zip",
              size: "480 MB", // 333
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.brain.zip",
              },
              desc: "Expression matrix for brain",
            },
            {
              name: "exp.data.colon.rds.zip",
              size: "299 MB", // 211
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.colon.zip",
              },
              desc: "Expression matrix for colon",
            },
            {
              name: "exp.data.heart.rds.zip",
              size: "378 MB", // 168
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.heart.zip",
              },
              desc: "Expression matrix for heart",
            },
            {
              name: "exp.data.intestine.rds.zip",
              size: "314 MB", // 168
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.intestine.zip",
              },
              desc: "Expression matrix for intestine",
            },
            {
              name: "exp.data.kidney.rds.zip",
              size: "286 MB", // 234
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.kidney.zip",
              },
              desc: "Expression matrix for kidney",
            },
            {
              name: "exp.data.lacrimal.rds.zip",
              size: "382 MB", // 194
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.lacrimal.zip",
              },
              desc: "Expression matrix for lacrimal",
            },
            {
              name: "exp.data.liver.rds.zip",
              size: "248 MB", // 284
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.liver.zip",
              },
              desc: "Expression matrix for liver",
            },
            {
              name: "exp.data.lung.rds.zip",
              size: "688 MB", // 378
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.lung.zip",
              },
              desc: "Expression matrix for lung",
            },
            {
              name: "exp.data.pancreas.rds.zip",
              size: "339 MB", // 172
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.pancreas.zip",
              },
              desc: "Expression matrix for pancreas",
            },
            {
              name: "exp.data.salivary.rds.zip",
              size: "261 MB", // 171
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.salivary.zip",
              },
              desc: "Expression matrix for salivary",
            },
            {
              name: "exp.data.skeletalmuscle.rds.zip",
              size: "396 MB", // 346
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.skeletalmuscle.zip",
              },
              desc: "Expression matrix for skeletal muscle",
            },
            {
              name: "exp.data.spleen.rds.zip",
              size: "354 MB", // 272
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.spleen.zip",
              },
              desc: "Expression matrix for spleen",
            },
            {
              name: "exp.data.stomach.rds.zip",
              size: "448 MB", // 215
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.stomach.zip",
              },
              desc: "Expression matrix for stomach",
            },
            {
              name: "exp.data.thymus.rds.zip",
              size: "323 MB", // 163
              filePath: {
                path: "expressionMatrix",
                file: "exp.data.thymus.zip",
              },
              desc: "Expression matrix for thymus",
            },
          ],
        },
        // {
        //   labelTitle: " DEG list (2 files)",
        //   contentMsg:
        //     "Differentially expressed genes in each cell type across 17 tissues and their related summary files.",
        //   downloadAll: {
        //     path: "degList",
        //     file: "DEG.zip",
        //   },
        //   downloadSubList: [
        //     {
        //       name: "DEGs.csv",
        //       size: "4.82 MB",  // 4.63
        //       filePath: {
        //         path: "degList",
        //         file: "DEGs.csv",
        //       },
        //       desc: "DEGs across 17 tissues in the CASA",
        //     },
        //     // {
        //     //   name: "DEGs_number_FDvsFS.csv",
        //     //   size: "15.8 KB",
        //     //   filePath: {
        //     //     path: "degList",
        //     //     file: "DEGs_number_FDvsFS.csv",
        //     //   },
        //     //   desc: "The number of DEGs generated from the comparison of FDvsFS (androgen supplement vs sham in female mice) in each cell type",
        //     // },
        //     // {
        //     //   name: "DEGs_number_MCvsMS.csv",
        //     //   size: "15.8 KB",
        //     //   filePath: {
        //     //     path: "degList",
        //     //     file: "DEGs_number_MCvsMS.csv",
        //     //   },
        //     //   desc: "The number of DEGs generated from the comparison of MCvsMS (androgen deprivation vs sham in male mice) in each cell type",
        //     // },
        //     // {
        //     //   name: "DEGs_number_MSvsFS.csv",
        //     //   size: "15.9 KB",
        //     //   filePath: {
        //     //     path: "degList",
        //     //     file: "DEGs_number_MSvsFS.csv",
        //     //   },
        //     //   desc: "The number of DEGs generated from the comparison of MSvsFS (male mice vs female mice) in each cell type",
        //     // },
        //     {
        //       name: "DEGs_number_tissue.csv",
        //       size: "14.60 KB",  // 4.44
        //       filePath: {
        //         path: "degList",
        //         file: "DEGs_number_tissue.csv",
        //       },
        //       desc: "The number of DEGs generated from the three comparisons in each tissue",
        //     },
        //   ],
        // },
        // {
        //   labelTitle: " Pathway list (1 file)",
        //   contentMsg:
        //     // "Significantly enriched biological pathways based on DEGs of each tissue and their related summary files.",
        //     "Significantly enriched biological pathways based on the DEGs in each cell type across the 17 tissues.",
        //   downloadAll: {
        //     path: "pathwayList",
        //     file: "Biological_pathways.zip",
        //   },
        //   downloadSubList: [
        //     {
        //       name: "Biological_pathways.csv",
        //       size: "7.48 MB",  // 7.33   // 6.56
        //       filePath: {
        //         path: "pathwayList",
        //         file: "Biological_pathways.csv",
        //       },
        //       desc: "Significantly enriched biological pathways (p < 0.01 & q < 0.01) based on the DEGs in each cell type across the 17 tissues.",
        //     },
        //     // {
        //     //   name: "FDvsFS.xlsx",
        //     //   size: "1.16 MB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "FDvsFS.xlsx",
        //     //   },
        //     //   desc: "Significantly enriched biological pathways based on the DEGs from the comparison of FDvsFS (androgen supplement vs sham in female mice)",
        //     // },
        //     // {
        //     //   name: "MCvsMS.xlsx",
        //     //   size: "874 KB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "MCvsMS.xlsx",
        //     //   },
        //     //   desc: "Significantly enriched biological pathways based on the DEGs from the comparison of MCvsMS (androgen deprivation vs sham in male mice)",
        //     // },
        //     // {
        //     //   name: "MSvsFS.xlsx",
        //     //   size: "1.26 MB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "MSvsFS.xlsx",
        //     //   },
        //     //   desc: "Significantly enriched biological pathways based on the DEGs from the comparison of MSvsFS (male mice vs female mice)",
        //     // },
        //     // {
        //     //   name: "Up_regulated_pathways_FDvsFS.xlsx",
        //     //   size: "238 KB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "Up_regulated_pathways_FDvsFS.xlsx",
        //     //   },
        //     //   desc: "Cross-tissue summary for up-regulated biological pathways of FDvsFS (androgen supplement vs sham in female mice)",
        //     // },
        //     // {
        //     //   name: "Up_regulated_pathways_MCvsMS.xlsx",
        //     //   size: "269 KB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "Up_regulated_pathways_MCvsMS.xlsx",
        //     //   },
        //     //   desc: "Cross-tissue summary for up-regulated biological pathways of MCvsMS (androgen deprivation vs sham in male mice)",
        //     // },
        //     // {
        //     //   name: "Up_regulated_pathways_MSvsFS.xlsx",
        //     //   size: "266 KB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "Up_regulated_pathways_MSvsFS.xlsx",
        //     //   },
        //     //   desc: "Cross-tissue summary for up-regulated biological pathways of MSvsFS (male mice vs female mice)",
        //     // },
        //     // {
        //     //   name: "Down_regulated_pathways_FDvsFS.xlsx",
        //     //   size: "312 KB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "Down_regulated_pathways_FDvsFS.xlsx",
        //     //   },
        //     //   desc: "Cross-tissue summary for down-regulated biological pathways of FDvsFS (androgen supplement vs sham in female mice)",
        //     // },
        //     // {
        //     //   name: "Down_regulated_pathways_MCvsMS.xlsx",
        //     //   size: "163 KB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "Down_regulated_pathways_MCvsMS.xlsx",
        //     //   },
        //     //   desc: "Cross-tissue summary for down-regulated biological pathways of MCvsMS (androgen deprivation vs sham in male mice)",
        //     // },
        //     // {
        //     //   name: "Down_regulated_pathways_MSvsFS.xlsx",
        //     //   size: "316 KB",
        //     //   filePath: {
        //     //     path: "pathwayList",
        //     //     file: "Down_regulated_pathways_MSvsFS.xlsx",
        //     //   },
        //     //   desc: "Cross-tissue summary for down-regulated biological pathways of MSvsFS (male mice vs female mice)",
        //     // },
        //   ],
        // },
        // {
        //   labelTitle: " AASB-DEG (1 file)",
        //   contentMsg:
        //     "Androgen-associated sex-biased DEGs (AASB-DEGs) are sex-biased and regulated by both androgen deprivation and supplement.",
        //   downloadAll: {
        //     path: "aasbDegs",
        //     file: "AASB-DEGs.xlsx",
        //   },
        //   downloadSubList: [
        //     {
        //       name: "AASB-DEGs.xlsx",
        //       size: "67.0 KB",  // 62.9
        //       filePath: {
        //         path: "aasbDegs",
        //         file: "AASB-DEGs.xlsx",
        //       },
        //       desc: "Androgen-associated sex-biased DEGs (AASB-DEGs) are sex-biased and regulated by both androgen deprivation and supplement.",
        //     },
        //   ],
        // },
        // {
        //   labelTitle: " AASB-biological pathways (1 file)",
        //   contentMsg:
        //     "The biological pathways in which AASB-DEGs are involved.",
        //   downloadAll: {
        //     path: "aasbBioPathway",
        //     file: "AASB-biological_pathways.xlsx",
        //   },
        //   downloadSubList: [
        //     {
        //       name: "AASB-biological_pathways.xlsx",
        //       size: "320 KB", // 144  // 170
        //       filePath: {
        //         path: "aasbBioPathway",
        //         file: "AASB-biological_pathways.xlsx",
        //       },
        //       desc: "Significantly enriched biological pathways (p < 0.01 & q < 0.01) based on the AASB-DEGs in each cell type across the 17 tissues.",
        //     },
        //   ],
        // },
        // {
        //   labelTitle: " The primary or secondary effects of androgen/AR axis (1 file)",
        //   contentMsg:
        //     // "The File of Androgen's effect classification are involved.",
        //     "The categorization of the effects of androgen/Ar axis across tissues.",
        //   downloadAll: {
        //     path: "andrEffect",
        //     // file: "The effect of androgen or Ar axis.csv",
        //     file: "The primary and secondary effects of androgen or Ar axis.csv",
        //   },
        //   downloadSubList: [
        //     {
        //       // name: "The effect of androgen or Ar axis.csv",
        //       name: "The primary and secondary effects of androgen/Ar axis.csv",
        //       size: "3.12 MB",  // 2.89
        //       filePath: {
        //         path: "andrEffect",
        //         // file: "The effect of androgen or Ar axis.csv",
        //         file: "The primary and secondary effects of androgen or Ar axis.csv",
        //       },
        //       // desc: "The effect of androgen or Ar axis",
        //       desc: "The primary and secondary effects of androgen or Ar axis",
        //     },
        //   ],
        // },
      ],
      downloadList2: [
        {
          labelTitle: " DEG list (2 files)",
          contentMsg:
            "Differentially expressed genes in each cell type across 17 tissues and their related summary files.",
          downloadAll: {
            path: "degList",
            file: "DEG.zip",
          },
          downloadSubList: [
            {
              name: "DEGs.csv",
              size: "4.82 MB",  // 4.63
              filePath: {
                path: "degList",
                file: "DEGs.csv",
              },
              desc: "DEGs across 17 tissues in the CASA",
            },
            // {
            //   name: "DEGs_number_FDvsFS.csv",
            //   size: "15.8 KB",
            //   filePath: {
            //     path: "degList",
            //     file: "DEGs_number_FDvsFS.csv",
            //   },
            //   desc: "The number of DEGs generated from the comparison of FDvsFS (androgen supplement vs sham in female mice) in each cell type",
            // },
            // {
            //   name: "DEGs_number_MCvsMS.csv",
            //   size: "15.8 KB",
            //   filePath: {
            //     path: "degList",
            //     file: "DEGs_number_MCvsMS.csv",
            //   },
            //   desc: "The number of DEGs generated from the comparison of MCvsMS (androgen deprivation vs sham in male mice) in each cell type",
            // },
            // {
            //   name: "DEGs_number_MSvsFS.csv",
            //   size: "15.9 KB",
            //   filePath: {
            //     path: "degList",
            //     file: "DEGs_number_MSvsFS.csv",
            //   },
            //   desc: "The number of DEGs generated from the comparison of MSvsFS (male mice vs female mice) in each cell type",
            // },
            {
              name: "DEGs_number_tissue.csv",
              size: "14.60 KB",  // 4.44
              filePath: {
                path: "degList",
                file: "DEGs_number_tissue.csv",
              },
              desc: "The number of DEGs generated from the three comparisons in each tissue",
            },
          ],
        },
        {
          labelTitle: " Pathway list (1 file)",
          contentMsg:
            // "Significantly enriched biological pathways based on DEGs of each tissue and their related summary files.",
            "Significantly enriched biological pathways based on the DEGs in each cell type across the 17 tissues.",
          downloadAll: {
            path: "pathwayList",
            file: "Biological_pathways.zip",
          },
          downloadSubList: [
            {
              name: "Biological_pathways.csv",
              size: "7.48 MB",  // 7.33   // 6.56
              filePath: {
                path: "pathwayList",
                file: "Biological_pathways.csv",
              },
              desc: "Significantly enriched biological pathways (p < 0.01 & q < 0.01) based on the DEGs in each cell type across the 17 tissues.",
            },
            // {
            //   name: "FDvsFS.xlsx",
            //   size: "1.16 MB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "FDvsFS.xlsx",
            //   },
            //   desc: "Significantly enriched biological pathways based on the DEGs from the comparison of FDvsFS (androgen supplement vs sham in female mice)",
            // },
            // {
            //   name: "MCvsMS.xlsx",
            //   size: "874 KB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "MCvsMS.xlsx",
            //   },
            //   desc: "Significantly enriched biological pathways based on the DEGs from the comparison of MCvsMS (androgen deprivation vs sham in male mice)",
            // },
            // {
            //   name: "MSvsFS.xlsx",
            //   size: "1.26 MB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "MSvsFS.xlsx",
            //   },
            //   desc: "Significantly enriched biological pathways based on the DEGs from the comparison of MSvsFS (male mice vs female mice)",
            // },
            // {
            //   name: "Up_regulated_pathways_FDvsFS.xlsx",
            //   size: "238 KB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "Up_regulated_pathways_FDvsFS.xlsx",
            //   },
            //   desc: "Cross-tissue summary for up-regulated biological pathways of FDvsFS (androgen supplement vs sham in female mice)",
            // },
            // {
            //   name: "Up_regulated_pathways_MCvsMS.xlsx",
            //   size: "269 KB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "Up_regulated_pathways_MCvsMS.xlsx",
            //   },
            //   desc: "Cross-tissue summary for up-regulated biological pathways of MCvsMS (androgen deprivation vs sham in male mice)",
            // },
            // {
            //   name: "Up_regulated_pathways_MSvsFS.xlsx",
            //   size: "266 KB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "Up_regulated_pathways_MSvsFS.xlsx",
            //   },
            //   desc: "Cross-tissue summary for up-regulated biological pathways of MSvsFS (male mice vs female mice)",
            // },
            // {
            //   name: "Down_regulated_pathways_FDvsFS.xlsx",
            //   size: "312 KB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "Down_regulated_pathways_FDvsFS.xlsx",
            //   },
            //   desc: "Cross-tissue summary for down-regulated biological pathways of FDvsFS (androgen supplement vs sham in female mice)",
            // },
            // {
            //   name: "Down_regulated_pathways_MCvsMS.xlsx",
            //   size: "163 KB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "Down_regulated_pathways_MCvsMS.xlsx",
            //   },
            //   desc: "Cross-tissue summary for down-regulated biological pathways of MCvsMS (androgen deprivation vs sham in male mice)",
            // },
            // {
            //   name: "Down_regulated_pathways_MSvsFS.xlsx",
            //   size: "316 KB",
            //   filePath: {
            //     path: "pathwayList",
            //     file: "Down_regulated_pathways_MSvsFS.xlsx",
            //   },
            //   desc: "Cross-tissue summary for down-regulated biological pathways of MSvsFS (male mice vs female mice)",
            // },
          ],
        },
        {
          labelTitle: " AASB-DEG (1 file)",
          contentMsg:
            "Androgen-associated sex-biased DEGs (AASB-DEGs) are sex-biased and regulated by both androgen deprivation and supplement.",
          downloadAll: {
            path: "aasbDegs",
            file: "AASB-DEGs.xlsx",
          },
          downloadSubList: [
            {
              name: "AASB-DEGs.xlsx",
              size: "67.0 KB",  // 62.9
              filePath: {
                path: "aasbDegs",
                file: "AASB-DEGs.xlsx",
              },
              desc: "Androgen-associated sex-biased DEGs (AASB-DEGs) are sex-biased and regulated by both androgen deprivation and supplement.",
            },
          ],
        },
        {
          labelTitle: " AASB-biological pathways (1 file)",
          contentMsg:
            "The biological pathways in which AASB-DEGs are involved.",
          downloadAll: {
            path: "aasbBioPathway",
            file: "AASB-biological_pathways.xlsx",
          },
          downloadSubList: [
            {
              name: "AASB-biological_pathways.xlsx",
              size: "320 KB", // 144  // 170
              filePath: {
                path: "aasbBioPathway",
                file: "AASB-biological_pathways.xlsx",
              },
              desc: "Significantly enriched biological pathways (p < 0.01 & q < 0.01) based on the AASB-DEGs in each cell type across the 17 tissues.",
            },
          ],
        },
        {
          labelTitle: " The primary or secondary effects of androgen/AR axis (1 file)",
          contentMsg:
            // "The File of Androgen's effect classification are involved.",
            "The categorization of the effects of androgen/Ar axis across tissues.",
          downloadAll: {
            path: "andrEffect",
            // file: "The effect of androgen or Ar axis.csv",
            file: "The primary and secondary effects of androgen or Ar axis.csv",
          },
          downloadSubList: [
            {
              // name: "The effect of androgen or Ar axis.csv",
              name: "The primary and secondary effects of androgen/Ar axis.csv",
              size: "3.12 MB",  // 2.89
              filePath: {
                path: "andrEffect",
                // file: "The effect of androgen or Ar axis.csv",
                file: "The primary and secondary effects of androgen or Ar axis.csv",
              },
              // desc: "The effect of androgen or Ar axis",
              desc: "The primary and secondary effects of androgen or Ar axis",
            },
          ],
        },
      ],
    };
  },
  methods: {
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          // 将表单数据添加到 FormData 对象中
          Object.keys(this.$refs[formName].model).forEach(key => {
            formData.append(key, this.$refs[formName].model[key]);
          });
          // 调用后端接口提交表单数据
          this.$axios.post('/submit-form/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data' // 设置请求头
            }
          })
            .then((response) => {
              // console.log(response.data);
              // alert('Form submitted successfully!');
              this.formSubmitted = true; // 提交成功，显示成功提示
              this.$message({
                message: 'Submitted successfully!',
                type: 'success'
              });
            })
            .catch((error) => {
              // console.error(error);
              this.$message({
                message: 'Failed to submit form. Please try again.',
                type: 'warning'
              });
            });
        } else {
          // console.log('Form validation failed!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    djangoDownloadZipFile(url, options = {}) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "post",
          url: url,
          data: options,
          responseType: "blob",
        }).then(
          (response) => {
            // console.log("下载响应", response);
            resolve(response.data);
            let blob = new Blob([response.data], {
              type: "application/zip",
            });
            let fileNameEncode =
              response.headers["content-disposition"].split("filename=")[1];
            let fileName = decodeURIComponent(fileNameEncode);
            // console.log("fileName", fileName);
            if (window.navigator.msSaveOrOpenBlob) {
              // console.log(2);
              navigator.msSaveBlob(blob, fileName);
            } else {
              // console.log(3);
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              window.URL.revokeObjectURL(link.href);
            }
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    djangoDownloadCsvFile(url, options = {}) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "post",
          url: url,
          data: options,
          responseType: "blob",
        }).then(
          (response) => {
            // console.log("下载响应", response);
            resolve(response.data);
            let blob = new Blob([response.data], {
              type: "text/csv",
            });
            let fileNameEncode =
              response.headers["content-disposition"].split("filename=")[1];
            let fileName = decodeURIComponent(fileNameEncode);
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              window.URL.revokeObjectURL(link.href);
            }
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    djangoDownloadXlsxFile(url, options = {}) {
      return new Promise((resolve, reject) => {
        // console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
        // axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
        this.$axios({
          method: "post",
          url: url, // 请求地址
          data: options, // 参数
          responseType: "blob", // 表明返回服务器返回的数据类型
        }).then(
          (response) => {
            // console.log("下载响应", response);
            resolve(response.data);
            let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            // console.log(blob);
            // 切割出文件名
            let fileNameEncode =
              response.headers["content-disposition"].split("filename=")[1];
            // 解码
            let fileName = decodeURIComponent(fileNameEncode);
            // console.log("fileName",fileName)
            if (window.navigator.msSaveOrOpenBlob) {
              // console.log(2)
              navigator.msSaveBlob(blob, fileName);
            } else {
              // console.log(3)
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    // 下载文件
    downloadFile(e) {
      let postUrl = "download/";
      let params = {
        file_path: e.path,
        file_name: e.file,
      };
      // console.log("下载参数", params);
      let file_type = e.file.split(".").slice(-1)[0];
      // console.log(file_type);
      switch (file_type) {
        case "zip":
          this.djangoDownloadZipFile(postUrl, params);
          break;
        case "csv":
          this.djangoDownloadCsvFile(postUrl, params);
          break;
        case "xlsx":
          this.djangoDownloadXlsxFile(postUrl, params);
          break;
      }
    },
    show(a, b, c) {
      // console.log(a);
      this.$Modal.info({
        title: "File Info",
        content: `File：${a}<br>size：${b}<br>Desc：${c}`,
      });
    },
  },
};
</script>

<style scoped>
/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-card {
  width: 1320px;
  margin: 20px 0 100px 0;
}

.box-card-item {
  font-size: 20px;
  font-weight: bold;
}

.box-card-item-icon {
  color: #223468;
}

/* download */
.box-card-download {
  margin: 15px 0 20px 0;
  width: 100%;
}

.download-item {
  font-size: 18px;
  font-weight: bold;
}

.download-item-icon {
  color: #223468;
}

.download-item-header {
  margin: 15px 0;
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.download-item-content {
  width: 100%;
  font-size: 16px;
  text-align: justify;
  line-height: 1.6;
  white-space: pre-wrap;
}

.download-item-table {
  margin-bottom: 30px;
}

/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }

  .download-item-header {
    margin: 15px 0;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>