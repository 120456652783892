<template>
  <div>
    <el-backtop :bottom="60"></el-backtop>
  </div>
</template>

<script>
export default {
  name: "Backtop",
};
</script>

<style>
</style>