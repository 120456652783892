<template>
  <div>
    <div class="block-container">
      <!-- AASB-DEG (Androgen-associated sex-biased DEG) -->
      <el-card class="block-card">
        <div class="box-card-aasb-deg">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-down-left-and-up-right-to-center" />
            </span>
            <span> AASB-DEG (Androgen-associated sex-biased DEG)</span>
          </div>
        </div>
        <!-- Select -->
        <div class="select-container">
          <div class="select-components">
            <div class="select-title">Tissue</div>
            <div class="select-content">
              <el-select v-model="tissueValue" style="width: 100%" filterable @change="changeTissue(tissueValue)">
                <el-option v-for="item in tissueOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Cell type</div>
            <div class="select-content">
              <el-select v-model="cellTypeValue" style="width: 100%"
                @change="filterTable(tissueValue, cellTypeValue, changeValue)">
                <el-option v-for="item in cellTypeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Change</div>
            <div class="select-content">
              <el-select v-model="changeValue" style="width: 100%"
                @change="filterTable(tissueValue, cellTypeValue, changeValue)">
                <el-option v-for="item in changeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="box-card-table">
          <Table border stripe :loading="tableLoading" :columns="tableTitleColumns" :data="tableData" size="default"
            ref="table"></Table>
          <div class="table-page">
            <Page :total="total" @on-change="changePage" :current="pageNum" :page-size="pageSize"
              @on-page-size-change="handlePageSize" show-total show-elevator show-sizer
              :page-size-opts="[10, 20, 50, 100, 200, 500]" />
          </div>
        </div>
        <el-divider></el-divider>
        <!-- aasb-deg-network -->
        <div class="box-aasb-deg-network">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-draw-polygon" />
            </span>
            <span> AASB-DEG network</span>
          </div>
          <div class="box-card-aasb-deg-network">
            <div class="aasb-deg-network-web-index">
              <iframe :src="aasbDegWeb" scrolling="no" style="border: none" width="100%" height="100%"></iframe>
            </div>
          </div>
        </div>
      </el-card>

      <!-- AASB biological pathways (Androgen-associated sex-biased biological pathways) -->
      <el-card class="block-card" style="margin-bottom: 100px">
        <div class="box-card-aasb-deg">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-down-left-and-up-right-to-center" />
            </span>
            <span>
              AASB biological pathways (Androgen-associated sex-biased
              biological pathways)</span>
          </div>
        </div>
        <!-- Select -->
        <div class="select-container">
          <div class="select-components">
            <div class="select-title">Tissue</div>
            <div class="select-content">
              <el-select v-model="tissueValue2" style="width: 100%" filterable @change="changeTissue2(tissueValue2)">
                <el-option v-for="item in tissueOptions2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Cell type</div>
            <div class="select-content">
              <el-select v-model="cellTypeValue2" style="width: 100%" @change="
                filterTable2(tissueValue2, cellTypeValue2, changeValue2)
                ">
                <el-option v-for="item in cellTypeOptions2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">Change</div>
            <div class="select-content">
              <el-select v-model="changeValue2" style="width: 100%" @change="
                filterTable2(tissueValue2, cellTypeValue2, changeValue2)
                ">
                <el-option v-for="item in changeOptions2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="box-card-table">
          <Table border stripe :loading="tableLoading2" :columns="tableTitleColumns2" :data="tableData2" size="default"
            ref="table"></Table>
          <div class="table-page">
            <Page :total="total2" @on-change="changePage2" :current="pageNum2" :page-size="pageSize2"
              @on-page-size-change="handlePageSize2" show-total show-elevator show-sizer
              :page-size-opts="[10, 20, 50, 100, 200, 500]" />
          </div>
        </div>
        <el-divider></el-divider>
        <!-- aasb-deg-network -->
        <div class="box-aasb-deg-network">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-draw-polygon" />
            </span>
            <span> AASB biological pathways network</span>
          </div>
          <div class="box-card-aasb-deg-network">
            <div class="aasb-deg-network-web-index">
              <iframe :src="aasbPathwaysWeb" scrolling="no" style="border: none" width="100%" height="100%"></iframe>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import topImage from "/src/assets/clustering-banner.jpg";
export default {
  name: "AASBDEG",
  data() {
    return {
      topImgUrl: topImage,
      // url: "http://127.0.0.1:8000/",
      // AASB-DEG (Androgen-associated sex-biased DEG)
      tissueValue: "Adipose", // adipose
      tissueOptions: [
        {
          value: "Adipose", // adipose
          label: "Adipose",
        },
        {
          value: "Adrenal", // adrenal
          label: "Adrenal",
        },
        {
          value: "Bonemarrow",  // bone_marrow
          label: "Bone marrow",
        },
        {
          value: "Colon", // colon
          label: "Colon",
        },
        {
          value: "Heart", // heart
          label: "Heart",
        },
        {
          value: "Intestine", // intestine
          label: "Intestine",
        },
        {
          value: "Kidney", // kidney
          label: "Kidney",
        },
        {
          value: "Lacrimal", // lacrimal
          label: "Lacrimal",
        },
        {
          value: "Liver", // liver
          label: "Liver",
        },
        {
          value: "Lung", // lung
          label: "Lung",
        },
        {
          value: "Pancreas", // pancreas
          label: "Pancreas",
        },
        {
          value: "Salivary", // salivary
          label: "Salivary",
        },
        {
          value: "Skeletalmuscle",  // skeletal_muscle
          label: "Skeletal muscle",
        },
        {
          value: "Stomach", // stomach
          label: "Stomach",
        },
        {
          value: "Thymus", // thymus
          label: "Thymus",
        },
      ],
      cellTypeValue: "all",
      cellTypeOptionsDict: {
        'Adipose': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Adipocyte', 'label': 'Adipocyte' },
        { 'value': 'Adipose_stem_cell', 'label': 'Adipose_stem_cell' },
        { 'value': 'B_cell', 'label': 'B_cell' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'ILC2', 'label': 'ILC2' },
        { 'value': 'Macrophage_1', 'label': 'Macrophage_1' },
        { 'value': 'Macrophage_2', 'label': 'Macrophage_2' },
        { 'value': 'Mesothelial_cell', 'label': 'Mesothelial_cell' },
        { 'value': 'Preadipocyte_1', 'label': 'Preadipocyte_1' },
        { 'value': 'Preadipocyte_2', 'label': 'Preadipocyte_2' },
        { 'value': 'Preadipocyte_3', 'label': 'Preadipocyte_3' }],
        'Adrenal': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Cortex_1', 'label': 'Cortex_1' },
        { 'value': 'Cortex_2', 'label': 'Cortex_2' },
        { 'value': 'Cortex_3', 'label': 'Cortex_3' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Endothelial_3', 'label': 'Endothelial_3' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Smooth_muscle', 'label': 'Smooth_muscle' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Bonemarrow': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'DC', 'label': 'DC' },
        { 'value': 'Immature_B_1', 'label': 'Immature_B_1' },
        { 'value': 'Mature_B', 'label': 'Mature_B' },
        { 'value': 'Monocyte', 'label': 'Monocyte' },
        { 'value': 'NK_cell', 'label': 'NK_cell' },
        { 'value': 'Neutrophil_5', 'label': 'Neutrophil_5' }],
        'Colon': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Enterocyte_2', 'label': 'Enterocyte_2' },
        { 'value': 'Goblet', 'label': 'Goblet' }],
        'Heart': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Endothelial', 'label': 'Endothelial' },
        { 'value': 'Fibroblast_1', 'label': 'Fibroblast_1' },
        { 'value': 'Fibroblast_2', 'label': 'Fibroblast_2' },
        { 'value': 'Fibroblast_3', 'label': 'Fibroblast_3' },
        { 'value': 'Fibroblast_4', 'label': 'Fibroblast_4' },
        { 'value': 'NK_cell', 'label': 'NK_cell' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Intestine': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Enterocyte_1', 'label': 'Enterocyte_1' },
        { 'value': 'Enterocyte_2', 'label': 'Enterocyte_2' },
        { 'value': 'Enteroendocrine', 'label': 'Enteroendocrine' },
        { 'value': 'Goblet', 'label': 'Goblet' },
        { 'value': 'Paneth', 'label': 'Paneth' },
        { 'value': 'TA', 'label': 'TA' },
        { 'value': 'T_NK_cell_1', 'label': 'T_NK_cell_1' },
        { 'value': 'T_NK_cell_2', 'label': 'T_NK_cell_2' }],
        'Kidney': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'B_cell', 'label': 'B_cell' },
        {
          'value': 'Collecting_duct_intercalated',
          'label': 'Collecting_duct_intercalated'
        },
        {
          'value': 'Collecting_duct_principal_1',
          'label': 'Collecting_duct_principal_1'
        },
        {
          'value': 'Collecting_duct_principal_2',
          'label': 'Collecting_duct_principal_2'
        },
        { 'value': 'Collecting_transitional', 'label': 'Collecting_transitional' },
        { 'value': 'Connecting_tubule', 'label': 'Connecting_tubule' },
        { 'value': 'Descending_thin_lmb', 'label': 'Descending_thin_lmb' },
        { 'value': 'Distal_convoluted_tubule', 'label': 'Distal_convoluted_tubule' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Endothelial_3', 'label': 'Endothelial_3' },
        { 'value': 'Loop_of_Henle_cell', 'label': 'Loop_of_Henle_cell' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Mesangial_cell_1', 'label': 'Mesangial_cell_1' },
        { 'value': 'Mesangial_cell_2', 'label': 'Mesangial_cell_2' },
        { 'value': 'Monocyte_1', 'label': 'Monocyte_1' },
        { 'value': 'NK_cell', 'label': 'NK_cell' },
        { 'value': 'Podocyte_cell', 'label': 'Podocyte_cell' },
        {
          'value': 'Proximal_convoluted_tubule_1',
          'label': 'Proximal_convoluted_tubule_1'
        },
        {
          'value': 'Proximal_convoluted_tubule_2',
          'label': 'Proximal_convoluted_tubule_2'
        },
        {
          'value': 'Proximal_convoluted_tubule_3',
          'label': 'Proximal_convoluted_tubule_3'
        },
        {
          'value': 'Proximal_straight_tubule_1',
          'label': 'Proximal_straight_tubule_1'
        },
        {
          'value': 'Proximal_straight_tubule_2',
          'label': 'Proximal_straight_tubule_2'
        },
        { 'value': 'Smooth_muscle', 'label': 'Smooth_muscle' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Lacrimal': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Acinar_1', 'label': 'Acinar_1' },
        { 'value': 'Acinar_2', 'label': 'Acinar_2' },
        { 'value': 'Ductal', 'label': 'Ductal' },
        { 'value': 'Endothelial', 'label': 'Endothelial' },
        { 'value': 'Epithelial', 'label': 'Epithelial' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'ILC2', 'label': 'ILC2' },
        { 'value': 'Macrophage_1', 'label': 'Macrophage_1' },
        { 'value': 'Macrophage_2', 'label': 'Macrophage_2' },
        { 'value': 'Myoepithelial', 'label': 'Myoepithelial' },
        { 'value': 'Smooth_muscle', 'label': 'Smooth_muscle' },
        { 'value': 'T_NK_cell', 'label': 'T_NK_cell' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Liver': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'B_cell', 'label': 'B_cell' },
        { 'value': 'Cholangiocyte', 'label': 'Cholangiocyte' },
        { 'value': 'DC', 'label': 'DC' },
        { 'value': 'EC_Lym', 'label': 'EC_Lym' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Hepatocyte_1', 'label': 'Hepatocyte_1' },
        { 'value': 'Hepatocyte_2', 'label': 'Hepatocyte_2' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Mesenchyme', 'label': 'Mesenchyme' },
        { 'value': 'Neutrophil', 'label': 'Neutrophil' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Lung': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'B_cell', 'label': 'B_cell' },
        { 'value': 'Club_cell', 'label': 'Club_cell' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'Macrophage_2', 'label': 'Macrophage_2' }],
        'Pancreas': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Acinar', 'label': 'Acinar' },
        { 'value': 'B_cell', 'label': 'B_cell' },
        { 'value': 'Endocrine_alpha', 'label': 'Endocrine_alpha' },
        { 'value': 'Endocrine_beta', 'label': 'Endocrine_beta' },
        { 'value': 'Endocrine_delta', 'label': 'Endocrine_delta' },
        { 'value': 'Endothelial', 'label': 'Endothelial' },
        { 'value': 'Fibroblast_1', 'label': 'Fibroblast_1' },
        { 'value': 'Fibroblast_2', 'label': 'Fibroblast_2' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'NK_cell', 'label': 'NK_cell' },
        { 'value': 'Smooth_muscle', 'label': 'Smooth_muscle' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Salivary': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Basal_1', 'label': 'Basal_1' },
        { 'value': 'Basal_2', 'label': 'Basal_2' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'ILC2', 'label': 'ILC2' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Myoepithelial', 'label': 'Myoepithelial' },
        { 'value': 'NK_cell', 'label': 'NK_cell' },
        { 'value': 'SLG_Acinar', 'label': 'SLG_Acinar' },
        { 'value': 'SLG_Ductal', 'label': 'SLG_Ductal' },
        { 'value': 'SMG_Acinar', 'label': 'SMG_Acinar' },
        { 'value': 'SMG_Ductal_1', 'label': 'SMG_Ductal_1' },
        { 'value': 'SMG_Ductal_2', 'label': 'SMG_Ductal_2' },
        { 'value': 'SMG_Ductal_3', 'label': 'SMG_Ductal_3' },
        { 'value': 'SMG_Ductal_4', 'label': 'SMG_Ductal_4' },
        { 'value': 'SMG_Ductal_5', 'label': 'SMG_Ductal_5' },
        { 'value': 'Smooth_muscle', 'label': 'Smooth_muscle' }],
        'Skeletalmuscle': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'B_cell_1', 'label': 'B_cell_1' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Fibro_adipo_progenitor', 'label': 'Fibro_adipo_progenitor' },
        { 'value': 'Fibroblast_1', 'label': 'Fibroblast_1' },
        { 'value': 'Fibroblast_2', 'label': 'Fibroblast_2' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Monocyte', 'label': 'Monocyte' },
        { 'value': 'Muscle_stem_cell', 'label': 'Muscle_stem_cell' },
        { 'value': 'Neutrophil', 'label': 'Neutrophil' },
        { 'value': 'Skeletal_muscle', 'label': 'Skeletal_muscle' },
        { 'value': 'T_cell_1', 'label': 'T_cell_1' },
        { 'value': 'Tenocyte_cell', 'label': 'Tenocyte_cell' },
        { 'value': 'Treg', 'label': 'Treg' }],
        'Stomach': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Chief_cell', 'label': 'Chief_cell' },
        { 'value': 'Endothelial', 'label': 'Endothelial' },
        { 'value': 'Enterochromaffin_like', 'label': 'Enterochromaffin_like' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'Foveolar_cell_4', 'label': 'Foveolar_cell_4' },
        { 'value': 'G_D_cell', 'label': 'G_D_cell' },
        { 'value': 'ILC2', 'label': 'ILC2' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Mast_cell', 'label': 'Mast_cell' },
        { 'value': 'Mucous_cell', 'label': 'Mucous_cell' },
        { 'value': 'Parietal_cell', 'label': 'Parietal_cell' },
        { 'value': 'Tuft_cell', 'label': 'Tuft_cell' }],
        'Thymus': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'DNpro', 'label': 'DNpro' },
        { 'value': 'DPres_1', 'label': 'DPres_1' }]
      },
      changeValue: "all",
      changeOptions: [
        {
          value: "all",
          label: "All",
        },
        {
          value: "Positive",
          label: "Positive",
        },
        {
          value: "Negative",
          label: "Negative",
        },
      ],

      tableTitleColumns: [
        {
          title: "Tissue",
          key: "Tissue",
          // width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "Cell Type",
          key: "Cell_type",
          // width: 150,
          sortable: true,
          resizable: true,
        },
        {
          title: "Change",
          key: "Change",
          // width: 120,
          sortable: true,
          resizable: true,
        },
        {
          title: "Gene symbol",
          key: "Gene_symbol",
          // width: 150,
          sortable: true,
          resizable: true,
        },
      ],

      tableLoading: false,
      tableDataset: [],
      tableDataList: [],
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      aasbDegWeb: "/aasb_deg_web/index.html",

      // AASB biological pathways (Androgen-associated sex-biased biological pathways)
      tissueValue2: "Adipose",
      tissueOptions2: [
        {
          value: "Adipose", // adipose
          label: "Adipose",
        },
        {
          value: "Adrenal", // adrenal
          label: "Adrenal",
        },
        {
          value: "Bonemarrow", // bone_marrow
          label: "Bone marrow",
        },
        // {
        //   value: "Brain", // brain
        //   label: "Brain",
        // },
        // {
        //   value: "Colon", // colon
        //   label: "Colon",
        // },
        {
          value: "Heart", // heart
          label: "Heart",
        },
        {
          value: "Intestine", // intestine
          label: "Intestine",
        },
        {
          value: "Kidney",  // kidney
          label: "Kidney",
        },
        {
          value: "Lacrimal",  // lacrimal
          label: "Lacrimal",
        },
        {
          value: "Liver", // liver
          label: "Liver",
        },
        // {
        //   value: "Lung",  // lung
        //   label: "Lung",
        // },
        {
          value: "Pancreas",  // pancreas
          label: "Pancreas",
        },
        {
          value: "Salivary",  // salivary
          label: "Salivary",
        },
        {
          value: "Skeletalmuscle",  // skeletal_muscle
          label: "Skeletal muscle",
        },
        // {
        //   value: "Spleen",  // spleen
        //   label: "Spleen",
        // },
        {
          value: "Stomach", // stomach
          label: "Stomach",
        },
        // {
        //   value: "Thymus",  // thymus
        //   label: "Thymus",
        // },
      ],
      cellTypeValue2: "all",
      cellTypeOptionsDict2: {
        'Adipose': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Adipocyte', 'label': 'Adipocyte' },
        { 'value': 'Adipose_stem_cell', 'label': 'Adipose_stem_cell' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'Preadipocyte_1', 'label': 'Preadipocyte_1' },
        { 'value': 'Preadipocyte_2', 'label': 'Preadipocyte_2' },
        { 'value': 'Preadipocyte_3', 'label': 'Preadipocyte_3' }],
        'Adrenal': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Endothelial_3', 'label': 'Endothelial_3' }],
        'Bonemarrow': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Neutrophil_5', 'label': 'Neutrophil_5' }],
        'Heart': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Fibroblast_1', 'label': 'Fibroblast_1' },
        { 'value': 'Fibroblast_2', 'label': 'Fibroblast_2' },
        { 'value': 'Fibroblast_3', 'label': 'Fibroblast_3' }],
        'Intestine': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Enterocyte_1', 'label': 'Enterocyte_1' },
        { 'value': 'Paneth', 'label': 'Paneth' }],
        'Kidney': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Descending_thin_lmb', 'label': 'Descending_thin_lmb' },
        { 'value': 'Distal_convoluted_tubule', 'label': 'Distal_convoluted_tubule' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Endothelial_3', 'label': 'Endothelial_3' },
        { 'value': 'Loop_of_Henle_cell', 'label': 'Loop_of_Henle_cell' },
        { 'value': 'Mesangial_cell_1', 'label': 'Mesangial_cell_1' },
        {
          'value': 'Proximal_convoluted_tubule_1',
          'label': 'Proximal_convoluted_tubule_1'
        },
        {
          'value': 'Proximal_convoluted_tubule_2',
          'label': 'Proximal_convoluted_tubule_2'
        },
        {
          'value': 'Proximal_convoluted_tubule_3',
          'label': 'Proximal_convoluted_tubule_3'
        },
        {
          'value': 'Proximal_straight_tubule_1',
          'label': 'Proximal_straight_tubule_1'
        },
        {
          'value': 'Proximal_straight_tubule_2',
          'label': 'Proximal_straight_tubule_2'
        },
        { 'value': 'Smooth_muscle', 'label': 'Smooth_muscle' }],
        'Lacrimal': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Acinar_2', 'label': 'Acinar_2' },
        { 'value': 'Endothelial', 'label': 'Endothelial' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'Macrophage_1', 'label': 'Macrophage_1' },
        { 'value': 'Myoepithelial', 'label': 'Myoepithelial' },
        { 'value': 'T_NK_cell', 'label': 'T_NK_cell' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Liver': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'B_cell', 'label': 'B_cell' },
        { 'value': 'Cholangiocyte', 'label': 'Cholangiocyte' },
        { 'value': 'DC', 'label': 'DC' },
        { 'value': 'EC_Lym', 'label': 'EC_Lym' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Hepatocyte_1', 'label': 'Hepatocyte_1' },
        { 'value': 'Hepatocyte_2', 'label': 'Hepatocyte_2' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Mesenchyme', 'label': 'Mesenchyme' },
        { 'value': 'Neutrophil', 'label': 'Neutrophil' },
        { 'value': 'T_cell', 'label': 'T_cell' }],
        'Pancreas': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Endocrine_beta', 'label': 'Endocrine_beta' },
        { 'value': 'Smooth_muscle', 'label': 'Smooth_muscle' }],
        'Salivary': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Basal_1', 'label': 'Basal_1' },
        { 'value': 'Basal_2', 'label': 'Basal_2' },
        { 'value': 'Endothelial_1', 'label': 'Endothelial_1' },
        { 'value': 'Endothelial_2', 'label': 'Endothelial_2' },
        { 'value': 'Fibroblast', 'label': 'Fibroblast' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'NK_cell', 'label': 'NK_cell' },
        { 'value': 'SMG_Ductal_1', 'label': 'SMG_Ductal_1' },
        { 'value': 'SMG_Ductal_2', 'label': 'SMG_Ductal_2' },
        { 'value': 'SMG_Ductal_3', 'label': 'SMG_Ductal_3' }],
        'Skeletalmuscle': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Fibro_adipo_progenitor', 'label': 'Fibro_adipo_progenitor' },
        { 'value': 'Fibroblast_2', 'label': 'Fibroblast_2' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Skeletal_muscle', 'label': 'Skeletal_muscle' }],
        'Stomach': [{ 'value': 'all', 'label': 'All cell types' },
        { 'value': 'Endothelial', 'label': 'Endothelial' },
        { 'value': 'Macrophage', 'label': 'Macrophage' },
        { 'value': 'Tuft_cell', 'label': 'Tuft_cell' }]
      },
      changeValue2: "all",
      changeOptions2: [
        {
          value: "all",
          label: "All",
        },
        {
          value: "Positive",
          label: "Positive",
        },
        {
          value: "Negative",
          label: "Negative",
        },
      ],
      tableTitleColumns2: [
        {
          title: "GO_ID",
          key: "ID",
          // width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "Description",
          key: "Description",
          // width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "pvalue",
          key: "pvalue",
          // width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "p.adjust",
          key: "p.adjust",
          // width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "geneID",
          key: "geneID",
          // width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "Tissue",
          key: "Tissue",
          // width: 130,
          sortable: true,
          resizable: true,
        },
        {
          title: "Cell_Type",
          key: "Cell_type",
          // width: 150,
          sortable: true,
          resizable: true,
        },
        {
          title: "Change",
          key: "Change",
          // width: 120,
          sortable: true,
          resizable: true,
        },
      ],
      tableLoading2: false,
      tableDataset2: [],
      tableDataList2: [],
      tableData2: [],
      pageNum2: 1,
      pageSize2: 10,

      aasbPathwaysWeb: "/aasb_pathways_web/index.html",
    };
  },
  created: function () {
    this.getTableDataset();
    this.getTableDataset2();
  },
  methods: {
    getTableDataset() {
      this.tableLoading = true;
      let that = this;
      this.$axios
        .get("json/", {
          // that.url + "json/", {
          params: {
            jsonID: "aasb_deg",
          },
        })
        .then(function (response) {
          // console.log(response.data.result);
          that.tableDataset = response.data.result;
          that.tableDataList = response.data.result;
          that.filterTable(
            that.tissueValue,
            that.cellTypeValue,
            that.changeValue
          );
          that.tableLoading = false;
        })
        .catch(function (err) {
          alert(err);
        });
    },
    getTableDataset2() {
      this.tableLoading2 = true;
      let that = this;
      this.$axios
        .get("json/", {
          // that.url + "json/", {
          params: {
            jsonID: "aasb_pathway",
          },
        })
        .then(function (response) {
          // console.log(response.data.result);
          that.tableDataset2 = response.data.result;
          that.tableDataList2 = response.data.result;
          that.filterTable2(
            that.tissueValue2,
            that.cellTypeValue2,
            that.changeValue2
          );
          that.tableLoading2 = false;
        })
        .catch(function (err) {
          alert(err);
        });
    },
    getTable() {
      // this.tableLoading = true;
      var that = this;
      that.tableLoading = true;
      setTimeout(() => {
        that.tableData = that.tableDataList.slice(0, that.pageSize);
        that.tableLoading = false;
      }, 1000);
    },
    getTable2() {
      // this.tableLoading = true;
      var that = this;
      that.tableLoading2 = true;
      setTimeout(() => {
        that.tableData2 = that.tableDataList2.slice(0, that.pageSize2);
        that.tableLoading2 = false;
      }, 1000);
    },
    changePage(index) {
      // this.tableData = this.tableDataList.slice((index - 1) * 10, index * 10);
      this.tableData = this.tableDataList.slice(
        (index - 1) * this.pageSize,
        index * this.pageSize
      );
      this.pageNum = index;
    },
    changePage2(index) {
      // this.tableData = this.tableDataList.slice((index - 1) * 10, index * 10);
      this.tableData2 = this.tableDataList2.slice(
        (index - 1) * this.pageSize2,
        index * this.pageSize2
      );
      this.pageNum2 = index;
    },
    handlePageSize(index) {
      // console.log(index);
      this.pageSize = index;
      this.getTable();
    },
    handlePageSize2(index) {
      // console.log(index);
      this.pageSize2 = index;
      this.getTable2();
    },
    changeTissue(t) {
      // console.log(t);
      this.selectTissue = t;
      this.cellTypeValue = this.$options.data().cellTypeValue;
      this.changeValue = this.$options.data().changeValue;
      this.filterTable(t, "all", "all");
    },
    changeTissue2(t) {
      // console.log(t);
      this.selectTissue2 = t;
      this.cellTypeValue2 = this.$options.data().cellTypeValue2;
      this.changeValue2 = this.$options.data().changeValue2;
      this.filterTable2(t, "all", "all");
    },
    filterTable(a, b, c) {
      this.tableLoading = true;
      let selectTissue = a;
      let selectCellType = b;
      let selectChange = c;
      // let primaryData = getTableDataset().tableDataList;
      let primaryData = this.tableDataset;
      // console.log(primaryData);
      let len = primaryData.length;
      let list = [];
      for (let item = 0; item < len; item++) {
        if (selectTissue != primaryData[item].Tissue) {
          continue;
        }
        if (selectCellType != "all") {
          if (selectCellType != primaryData[item].Cell_type) {
            continue;
          }
        }
        if (selectChange != "all") {
          if (selectChange != primaryData[item].Change) {
            continue;
          }
        }
        list.push(primaryData[item]);
      }
      // console.log(list);
      this.tableDataList = list;
      this.tableLoading = false;
      this.changePage(1);
    },
    filterTable2(a, b, c) {
      this.tableLoading2 = true;
      let selectTissue = a;
      let selectCellType = b;
      let selectChange = c;
      // let primaryData = getTableDataset().tableDataList;
      let primaryData = this.tableDataset2;
      let len = primaryData.length;
      let list = [];
      for (let item = 0; item < len; item++) {
        if (selectTissue != primaryData[item].Tissue) {
          continue;
        }
        if (selectCellType != "all") {
          if (selectCellType != primaryData[item].Cell_type) {
            continue;
          }
        }
        if (selectChange != "all") {
          if (selectChange != primaryData[item].Change) {
            continue;
          }
        }
        list.push(primaryData[item]);
      }
      // console.log(list);
      this.tableDataList2 = list;
      this.tableLoading2 = false;
      this.changePage2(1);
    },
  },
  computed: {
    total: function () {
      return this.tableDataList.length;
    },
    cellTypeOptions: function () {
      return this.cellTypeOptionsDict[this.tissueValue];
    },
    total2: function () {
      return this.tableDataList2.length;
    },
    cellTypeOptions2: function () {
      return this.cellTypeOptionsDict2[this.tissueValue2];
    },
  },
};
</script>

<style scoped>
/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-card {
  width: 1320px;
  margin: 20px 0;
}

.box-card-item {
  font-size: 20px;
  font-weight: bold;
}

.box-card-item-icon {
  color: #223468;
}

/* select */
.select-container {
  margin: 15px 0 20px 0;
  width: 100%;
  /* height: 100px; */
  background-color: #f4f6fc;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  /* border: 1px solid green; */
}

.select-components {
  width: 100%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
}

.select-title {
  font-size: 16px;
  margin: 0 0 10px 0;
}

.table-page {
  margin: 20px 0;
  text-align: center;
  /* border: 1px solid red; */
}

.box-card-aasb-deg-network {
  /* height: 700px; */
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dcdee2;
}

.aasb-deg-network-web-index {
  height: 670px;
  width: 100%;
  /* border: 1px solid green; */
}

/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }

  .select-container {
    flex-direction: column;
  }

  .select-components {
    margin: 2% 0;
    padding: 0;
    /* border: 1px solid red; */
  }
}</style>